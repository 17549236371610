import { Model, Attr, HasMany } from "spraypaint";
import { Survey } from "./Survey";
import { Service } from "./Service";
import { ServiceSubtype } from "__generated__/types";

@Model()
export class SurveyService extends Service {
  static jsonapiType = "services";
  @Attr() type!: "SURVEY";
  @Attr() subtype!: ServiceSubtype.Virtual | ServiceSubtype.Onsite;
  @Attr() plannedDatetimeRange!: {
    begin: string;
    end: string;
  } | null;
  @HasMany() surveys!: Survey[];
}

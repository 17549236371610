import { isNaN } from "lodash";

export function centsToDollars(amount: number | undefined) {
  return (amount || 0) / 100;
}

export function dollarsToCents(amount: number | undefined) {
  return Math.round((amount || 0) * 100);
}

/**
 * Flexible formatting of price string
 */
export function displayDollarsAndCents(
  amount: string | null | undefined,
  withSymbol = true
) {
  if (!amount) return "";
  let num = parseFloat(amount);

  if (withSymbol) {
    return !isNaN(num)
      ? num.toLocaleString("en-US", { style: "currency", currency: "USD" })
      : "";
  } else {
    return num.toFixed(2);
  }
}

/**
 * Formats number as usd price for display
 */
export function formatPriceUsd(price: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(price);
}

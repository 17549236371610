import { OfferStatus } from "constant/OfferStatus";
import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";
import { getClaimDisplayStatus } from "tools/getClaimDisplayStatus";
import { ClaimDisplayStatus, ClaimStatus, ClaimType } from "../types/Claims";
import { ApplicationRecord } from "./ApplicationRecord";
import { ClaimDetail } from "./ClaimDetail";
import { ClaimDetailOffer } from "./ClaimDetailOffer";
import { Order } from "./Order";

export const FILING_METHOD_CUSTOMER_APP = "Customer App";
@Model()
export class Claim extends ApplicationRecord {
  static jsonapiType = "claims";

  @Attr() claimType!: ClaimType;
  @Attr() closedAt?: string;
  @Attr() costAtPurchase!: string;
  @Attr() filingDate?: string;
  @Attr() filingMethod?: string;
  @Attr() lotNumber!: string;
  @Attr() manufacturer!: string;
  @Attr() orderId!: number;
  @Attr() repairEstimate!: string;
  @Attr() status!: ClaimStatus;
  @Attr() tagNumber!: string;
  @Attr() yearOfPurchase!: string;
  @HasOne() currentOffer!: ClaimDetailOffer | null;
  @BelongsTo() order!: Order;
  @HasMany() claimDetails!: ClaimDetail[];

  /**
   * Gets total value in cents counting each item in the claim
   */
  get totalValueInUsd() {
    return this.claimDetails
      .reduce((acc, curr) => {
        return (
          acc + (curr.valueEstimateUsd ? parseFloat(curr.valueEstimateUsd) : 0)
        );
      }, 0)
      .toFixed(2);
  }

  get claimDetailsUrl() {
    return `/claim/details?itemId=${this.id}`;
  }

  claimDetailById(id: string) {
    return this.claimDetails.find(({ id: _id }) => id === _id);
  }

  get displayStatus(): ClaimDisplayStatus {
    // We want to show action required if any of the claim details are
    // action required
    if (
      this.claimDetails &&
      this.claimDetails.length > 0 &&
      this.status !== "canceled"
    ) {
      const hasActionRequired = this.claimDetails.some((claimDetail) => {
        return claimDetail.displayStatus === "ACTION_REQUIRED";
      });
      if (hasActionRequired) {
        return "ACTION_REQUIRED";
      }
    }
    return getClaimDisplayStatus(this);
  }

  /**
   * Gets whether an offer as been accepted or paid for an associated claim detail
   */
  get hasAcceptedOffer() {
    return this.claimDetails.some(
      (claimDetail) =>
        ["offer_accepted", "paid"].includes(claimDetail.status) ||
        claimDetail.currentOffer?.status === OfferStatus.accepted
    );
  }
}

import { Attr, BelongsTo, HasMany, HasOne, Link, Model } from "spraypaint";
import { getClaimDisplayStatus } from "tools/getClaimDisplayStatus";
import { ClaimDisplayStatus, ClaimStatus } from "../types/Claims";
import { ApplicationRecord } from "./ApplicationRecord";
import { Claim } from "./Claim";
import { ClaimDetailOffer } from "./ClaimDetailOffer";
import { InventoryItem } from "./InventoryItem";

@Model()
export class ClaimDetail extends ApplicationRecord {
  static jsonapiType = "claim_details";
  @Attr() description!: string;
  @Attr() status!: ClaimStatus;
  @Attr() valueEstimateUsd!: string | null;
  @Attr() claimId?: string;
  @Attr() type?: string;
  @HasOne() currentOffer!: ClaimDetailOffer | null;
  @BelongsTo() claim!: Claim;
  @HasMany() claimDetailDocuments?: ClaimDetailDocument[];

  get displayStatus(): ClaimDisplayStatus {
    return getClaimDisplayStatus(this);
  }
}
@Model()
export class PropertyClaimDetail extends ClaimDetail {
  static jsonapiType = "property_claim_details";
}
@Model()
export class InconvenienceClaimDetail extends ClaimDetail {
  static jsonapiType = "inconvenience_claim_details";
}
@Model()
export class CargoClaimDetail extends ClaimDetail {
  static jsonapiType = "cargo_claim_details";

  @Attr() estimatedRepairCostUsd!: string | null;
  @Attr() itemCondition!: "damaged" | "missing";
  @Attr() manufacturerMakeModel!: string | null;
  @Attr() purchaseCostUsd!: string | null;
  @Attr() yearOfPurchase!: number | null;
  @Attr() inventoryItemId!: string;
  @BelongsTo(InventoryItem) inventoryItem!: InventoryItem;
}

export function isCargoClaimDetail(
  claim?: ClaimDetail
): claim is CargoClaimDetail {
  return claim !== undefined && "inventoryItemId" in claim;
}

@Model()
export class ClaimDetailDocument extends ApplicationRecord {
  static jsonapiType = "claim_details_documents";
  @Attr() filename!: string;
  @Attr() url!: string;
  @Link() download!: string;
  @Link() directDownload!: string;
  @Attr() canDelete!: boolean;
}

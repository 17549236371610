/**
 * Object that contains additional headers to send along with
 * every fetch request
 */
const fetchHeaders: {
  Authorization: string;
} = {
  Authorization: "",
};

/**
 * Statuses that represent a 'successful' response, all others will
 * throw an error.
 */
const successfulStatuses = [200, 201];

/**
 * Javascript fetch wrapper to get data from the backend.
 *
 * This wrapper will automatically add the bearer auth token in the header.
 *
 * Use this wrapper function exactly how you would normal fetch, for API/examples see:
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 * @param input
 * @param init
 * @returns Promise
 */
async function fetcher(
  ...[input, init]: Parameters<typeof fetch>
): Promise<Response> {
  const response = await fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      ...fetchHeaders,
    },
  });
  if (!successfulStatuses.includes(response.status)) {
    throw response;
  }
  return response;
}

/**
 * Issue a fetch request and return the text() response
 * @returns string response
 */
async function fetchText(
  ...[input, init]: Parameters<typeof fetch>
): Promise<string> {
  return (await fetcher(input, init)).text();
}

/**
 * Issue a fetch request and return the json() response
 * @returns Parsed javascript object response
 */
async function fetchJSON<R>(
  ...[input, init]: Parameters<typeof fetch>
): Promise<R> {
  return (await fetcher(input, init)).json();
}

/**
 * Issue a fetch request and return the blob() response
 * @returns blob
 */
async function fetchBlob(
  ...[input, init]: Parameters<typeof fetch>
): Promise<Blob> {
  return (await fetcher(input, init)).blob();
}

export { fetcher as fetch, fetchText, fetchJSON, fetchBlob, fetchHeaders };

import { Model, HasMany, Attr, BelongsTo } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { SurveyItem } from "./SurveyItem";
import { SurveySegment } from "./SurveySegment";
import { Order } from "./Order";
import { SurveyService } from "./SurveyService";
import { ShipmentWeightBreakdown } from "types";

@Model()
export class Survey extends ApplicationRecord {
  static jsonapiType = "surveys";

  @Attr() isComplete!: boolean;
  @Attr() surveyType!: "VIRTUAL" | "ONSITE";
  @Attr() isPrimary!: boolean;
  @Attr() isPendingApproval!: boolean;
  @Attr() estimatedWeight!: number;
  @Attr() estimatedProGearWeight!: number;
  @Attr() estimatedSpouseProGearWeight!: number;
  @Attr() notes?: string;
  @Attr() serviceId!: number;
  @HasMany() segments!: SurveySegment[];
  @HasMany() items!: SurveyItem[];

  get itemsNonProGear() {
    return this.items.filter(
      ({ category }) => category?.toUpperCase() === "HOUSEHOLD_GOODS"
    );
  }

  get itemsGunSafe() {
    return this.items.filter(({ definitionId }) => definitionId === "2248");
  }

  get estimatedWeightBreakDown() {
    return this.itemsNonProGear.reduce((breakdown, item) => {
      const shipmentType = item.segment.order.shipmentType;
      breakdown[shipmentType] =
        (breakdown[shipmentType] ?? 0) + item.getTotalWeight();
      return breakdown;
    }, {} as ShipmentWeightBreakdown);
  }

  @BelongsTo(Order) order!: Order;
  @BelongsTo(SurveyService) service!: SurveyService;
}

import { ApplicationRecord } from "./ApplicationRecord";
import { Attr, HasOne, Model, BelongsTo } from "spraypaint";
import { InventoryItem } from "./InventoryItem";

@Model()
export class LossOrDamageNotice extends ApplicationRecord {
  static jsonapiType = "loss_or_damage_notices";
  @Attr() id!: string;
  @Attr() noticeType!: "MISSING" | "DAMAGED";
  @Attr() description!: string;
  @Attr() createdBy!: "CUSTOMER" | "CREW";
  @Attr() reportedAt!: string;
  @Attr() inventoryItemId!: string;
  @HasOne() inventoryItem!: InventoryItem;
}

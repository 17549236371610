import { OrderStatus } from "types";
import { Order } from "models";

export const orderStatuses: OrderStatus[] = [
  {
    id: "RECEIVED",
    value: 10,
    label: "moveStatus.plan",
    percentComplete: 10,
    display: (order: Order) => true,
  },
  {
    id: "APPROVED",
    value: 10,
    label: "moveStatus.plan",
    percentComplete: 10,
    display: (order: Order) => false,
  },
  {
    id: "COUNSELING_COMPLETED",
    value: 20,
    label: "moveStatus.survey",
    percentComplete: 25,
    display: (order: Order) => true,
  },
  {
    id: "SURVEY_SCHEDULED",
    value: 20,
    label: "moveStatus.survey",
    percentComplete: 25,
    display: (order: Order) => false,
  },
  {
    id: "SURVEY_COMPLETED",
    value: 35,
    label: "moveStatus.review",
    percentComplete: 45,
    display: (order: Order) => false,
  },
  {
    id: "PLANNED",
    value: 35,
    label: "moveStatus.review",
    percentComplete: 45,
    display: (order: Order) => true,
  },
  {
    id: "PACK_CREW_DISPATCHED",
    value: 40,
    label: "moveStatus.pack",
    subtext: "Crew En Route", // underneath "Pack Your Belongings"
    percentComplete: 50,
    display: (order: Order) => false,
  },
  {
    id: "PACKING_STARTED",
    value: 40,
    label: "moveStatus.pack",
    subtext: "In Progress", // underneath "Pack Your Belongings"
    percentComplete: 50,
    display: (order: Order) => true,
  },
  {
    id: "PACKING_COMPLETED",
    value: 41,
    phaseComplete: true,
    label: "moveStatus.pack",
    percentComplete: 50,
    display: (order: Order) => false,
  },
  {
    id: "LOAD_CREW_DISPATCHED",
    value: 50,
    label: "moveStatus.load",
    subtext: "Crew En Route", // underneath "Load Truck"
    percentComplete: 60,
    display: (order: Order) => true,
  },
  {
    id: "LOADING_STARTED",
    value: 50,
    label: "moveStatus.load",
    subtext: "In Progress", // underneath "Load Truck"
    percentComplete: 60,
    display: (order: Order) => false,
  },
  {
    id: "IN_TRANSIT",
    value: 60,
    label: "moveStatus.inTransit",
    subtext: "In Progress",
    percentComplete: 70,
    display: (order: Order) => order.hasTransportation,
  },
  {
    id: "IN_STORAGE_IN_TRANSIT",
    value: 70,
    label: "moveStatus.inStorage",
    percentComplete: 80,
    display: (order: Order) => order.hasStorage,
  },
  {
    id: "IN_NON_TEMPORARY_STORAGE",
    value: 70,
    label: "moveStatus.inStorage",
    percentComplete: 80,
    display: (order: Order) => false,
  },
  {
    id: "DELIVERY_CREW_DISPATCHED",
    value: 90,
    label: "moveStatus.deliver",
    subtext: "Crew En Route", // underneath "Deliver shipment"
    percentComplete: 90,
    display: (order: Order) => false,
  },
  {
    id: "DELIVERY_STARTED",
    value: 90,
    label: "moveStatus.deliver",
    subtext: "Unloading", // underneath "Deliver shipment"
    percentComplete: 99,
    display: (order: Order) => true,
  },
  {
    id: "DELIVERY_COMPLETED",
    value: 90,
    label: "moveStatus.deliver",
    subtext: "Completed", // underneath "Deliver shipment"
    percentComplete: 100,
    phaseComplete: true,
    display: (order: Order) => false,
  },
  {
    id: "COMPLETED",
    value: 100,
    label: "moveStatus.complete",
    percentComplete: 100,
    display: (order: Order) => false,
  },
];

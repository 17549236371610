import { Model, Attr } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";

@Model()
export class Customer extends ApplicationRecord {
  static jsonapiType = "customers";

  @Attr() number!: string;
  @Attr() firstName!: string;
  @Attr() lastName!: string;
  @Attr() contactType!: string;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get weightUnits(): string {
    return "lbs";
  }

  get workEntitlements(): { personal: number; spouse: number } {
    return {
      personal: 0,
      spouse: 0,
    };
  }
}

import { createContext, useContext } from "react";
import type { useSnackbar } from "notistack";

type NotifyFunctionOptions = Parameters<
  ReturnType<typeof useSnackbar>["enqueueSnackbar"]
>[1];

export const NotificationContext = createContext<{
  notify: (message?: string, options?: NotifyFunctionOptions) => void;
}>({ notify() {} });

export function useNotify() {
  return useContext(NotificationContext);
}

import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { atomWithStorage } from "jotai/utils";
import { useCallback, createContext, ReactNode, useContext } from "react";
import { ApplicationRecord } from "models/ApplicationRecord";
import { debug } from "tools/debug";
import { disableAuth } from "./disableAuth";
import { useOrderLoader } from "services/orders/OrderLoaderContext";

type LogoutReason = "access_denied" | "rejected_disclaimer" | "user_idle";
const LOGOUT_INFO_KEY = "logout-info";
export const logoutInfoAtom = atomWithStorage<LogoutReason | null>(
  "logout-info",
  null
);

export const getLogoutInfo = () => {
  return typeof window !== "undefined"
    ? (window.localStorage.getItem(LOGOUT_INFO_KEY) as LogoutReason)
    : null;
};

export const setLogoutInfo = (reason: LogoutReason | null) => {
  if (reason === null) {
    window.localStorage.removeItem(LOGOUT_INFO_KEY);
  } else {
    window.localStorage.setItem(LOGOUT_INFO_KEY, reason);
  }
};

interface AuthInstance {
  signOut: (reason?: LogoutReason) => Promise<any>;
  signIn: () => Promise<any>;
  isAuthenticated: boolean;
  oktaAuth: OktaAuth | null;
  authState: AuthState | null;
  userId: string | null;
}

const mockLoggedinAuthInstance: AuthInstance = {
  signOut: () => Promise.resolve(),
  signIn: () => Promise.resolve(),
  isAuthenticated: true,
  oktaAuth: null,
  authState: null,
  userId: "123456",
};

/**
 * Helper hook to easily grab the auth context
 * @returns OktaContext
 */
export function useAuth(): AuthInstance {
  const { reset, order } = useOrderLoader();
  const { authState, oktaAuth } = useOktaAuth();

  const signOut = useCallback(async (reason?: LogoutReason) => {
    // Prevents useEffect weirdness in calling this function multiple
    // times
    if (ApplicationRecord.jwt === undefined) {
      console.warn("Already logging out, ignoring logout() call.");
      return;
    }

    ApplicationRecord.jwt = undefined;
    setLogoutInfo(reason || null);
    return oktaAuth
      .signOut({
        postLogoutRedirectUri: window.location.origin,
      })
      .then(() => {
        // Make sure we clear the order loader so it's in
        // the right state when the user tries to log in again
        reset(order?.id);
      });
  }, []);

  if (disableAuth) {
    return mockLoggedinAuthInstance;
  }

  return {
    isAuthenticated: authState?.isAuthenticated || false,
    signIn: () => oktaAuth.signInWithRedirect(),
    signOut,
    authState: authState,
    userId: authState?.idToken?.claims?.sub || null,
    oktaAuth: oktaAuth,
  };
}

/*
TODO: Set up a context to not create multiple instances of the auth instance from the hook
export const AuthContext = createContext<AuthInstance>(defaultAuthInstance);

export const AuthContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const auth = useAuthInternal();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
*/

import { Model, SpraypaintBase } from "spraypaint";
import { getEnv } from "tools";
import { MiddlewareStack } from "spraypaint";
import { SaveOptions } from "spraypaint/lib-esm/model";
import { middleware } from "./middleware";

type UpdateFn = () => void;

@Model()
export class ApplicationRecord extends SpraypaintBase {
  static baseUrl = getEnv("NEXT_PUBLIC_DEPOT_HOST");
  static apiNamespace = "/api/v1";
  static middlewareStack: MiddlewareStack = middleware;
  static generateAuthHeader(token: string) {
    return `Bearer ${token}`;
  }
  static get authHeader() {
    if (!ApplicationRecord.jwt) {
      throw new Error("User is not logged in");
    }
    return ApplicationRecord.generateAuthHeader(ApplicationRecord.jwt);
  }
  private subscriptions: Array<UpdateFn>;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.subscriptions = [];
    this.afterSync = this.updateUi;
  }

  /**
   * Subscribe to state changes on the record
   * @param fn
   */
  onUpdate(fn: UpdateFn) {
    if (!this.subscriptions.includes(fn)) {
      this.subscriptions.push(fn);
    }
  }

  /**
   * Unsubscribe previously subscribed callback
   * @param fn
   */
  offUpdate(fn: UpdateFn) {
    this.subscriptions = this.subscriptions.filter((f) => f !== fn);
  }

  /**
   * Triggers a re render in any component which uses
   * the `useSpraypaintRecord` hook
   */
  updateUi() {
    this.subscriptions.forEach((fn) => fn());
  }

  save<I extends SpraypaintBase>(
    this: I,
    options?: SaveOptions<I> | undefined
  ): Promise<boolean> {
    return super.save(options).then((result) => {
      if (result === true && this instanceof ApplicationRecord) {
        this.updateUi();
      }
      return result;
    });
  }
}

ApplicationRecord.sync = true;

export enum ShipmentType {
  boatHaulAway = "BOAT_HAUL_AWAY",
  boatTowAway = "BOAT_TOW_AWAY",
  hhgIntoNtsDomestic = "HHG_INTO_NTS_DOMESTIC",
  hhgOutOfNtsDomestic = "HHG_OUTOF_NTS_DOMESTIC",
  hhg = "HHG",
  internationalHhg = "INTERNATIONAL_HHG",
  ub = "UB",
  internationalUb = "INTERNATIONAL_UB",
  mobileHome = "MOBILE_HOME",
  ppm = "PPM",
}

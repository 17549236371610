import { Attr, BelongsTo, Model } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { Order } from "./Order";

@Model()
export class OrderAddress extends ApplicationRecord {
  static jsonapiType = "order_addresses";

  @Attr() street1!: string;
  @Attr() street2!: string;
  @Attr() locality!: string;
  @Attr() region!: string;
  @Attr() postalCode!: string;
  @Attr() country!: string;
  @Attr() description!: string;

  @BelongsTo() order!: Order;
}

const formFields = [
  "street1",
  "street2",
  "locality",
  "region",
  "postalCode",
  "country",
] as const;

export type OrderAddressFormFields = (typeof formFields)[number];

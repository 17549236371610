import { ShipmentType } from "types";

export const shipmentTypeLabelMap: { [key in ShipmentType]: string } = {
  BOAT_HAUL_AWAY: "Boat Haul Away",
  BOAT_TOW_AWAY: "Boat Tow Away",
  HHG_INTO_NTS_DOMESTIC: "Household Goods (NTS)",
  HHG_OUTOF_NTS_DOMESTIC: "Household Goods (NTS Release)",
  HHG: "Household Goods",
  INTERNATIONAL_HHG: "Household Goods",
  UB: "Unaccompanied Baggage",
  INTERNATIONAL_UB: "Unaccompanied Baggage",
  MOBILE_HOME: "Mobile Home",
  PPM: "Personally Procured Move",
};

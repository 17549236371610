import { Model, Attr, BelongsTo, HasMany } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { Order } from "./Order";
import { SurveyItem } from "./SurveyItem";

@Model()
export class SurveySegment extends ApplicationRecord {
  static jsonapiType = "segments";

  @Attr() id!: string;
  @Attr() from!: string;
  @Attr() to!: string;
  @Attr() type!: string;
  @Attr() surveyId!: string;
  @Attr() orderId!: string;
  @HasMany() items!: SurveyItem[];
  @BelongsTo(Order) order!: Order;
}

import { MiddlewareStack } from "spraypaint";
import { datadogRum } from "@datadog/browser-rum";

interface MaybeErrors extends JSON {
  errors?: Array<{ detail: string; code: string }>;
}

export const middleware = new MiddlewareStack();
middleware.afterFilters.push(async (response, json: MaybeErrors) => {
  if (response.status === 401) {
    if (json.errors && json.errors.length > 0) {
      // A 1501 in the error means that this 401 is a rejection by hub
      // because the user signed in does not have a customer role. In this case
      // we redirect them to a page to inform the user of what's going on
      // as this could easily happen to HSA customer service employees or admins
      // who think they are trying to get into CRM.
      if (json.errors[0].detail.includes("1501")) {
        if (!window.location.pathname.startsWith("/no_access")) {
          window.location.replace("/no_access");
        }
        return;
      }
    }

    // Any other case of getting a 401 means something else was wrong.
    // The error will be bubbled up.
    datadogRum.addError(new Error(`User recieved 401 ${JSON.stringify(json)}`));
  }
});

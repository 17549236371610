import { Attr, Model } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";

@Model()
export class Entitlement extends ApplicationRecord {
  static jsonapiType = "entitlements";

  @Attr() orderId!: number;
  @Attr() transcomId!: string;
  @Attr() rank!: string;
  @Attr() rankAbbreviation!: string;
  @Attr() payGrade!: string;
  @Attr() branch!: string;
  @Attr() authorizedWeight!: number;
  @Attr() dependentsAuthorized!: boolean;
  @Attr() nonTemporaryStorage!: boolean;
  @Attr() privatelyOwnedVehicle!: boolean;
  @Attr() proGearWeight!: number;
  @Attr() proGearWeightSpouse!: number;
  @Attr() gunSafeWeight!: number;
  @Attr() requiredMedicalEquipmentWeight!: number;
  @Attr() organizationalClothingAndIndividualEquipment!: boolean;
  @Attr() storageInTransit!: number;
  @Attr() totalWeight!: number;
  @Attr() totalDependents!: number;
  @Attr() userPreferenceGunSafe!: boolean | null;
  @Attr() userPreferenceFirearm!: boolean | null;
  @Attr() userPreferenceMotorcycle!: boolean | null;
  @Attr() userPreferencePrivatelyOwnedVehicle!: boolean | null;
  @Attr() userPreferenceBoat!: boolean | null;
  @Attr() userPreferenceConsumables!: boolean | null;
  @Attr() userPreferenceMobileHome!: boolean | null;
  @Attr() userPreferenceAlcoholicBeverages!: boolean | null;
}

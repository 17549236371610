import { Attr, BelongsTo, HasMany, Model } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { OfferStatus } from "../constant/OfferStatus";
import { ClaimDetailOffer } from "./ClaimDetailOffer";
import { ClaimDisplayStatus } from "types/Claims";

@Model()
export class ClaimDetailOfferActivity extends ApplicationRecord {
  static jsonapiType = "claim_detail_offer_activities";
  @Attr() description!: string;
  @Attr() status!: OfferStatus;
  @Attr() valueAmountUsd?: string | null;
  @Attr() counteredDescription?: string;
  @Attr() counteredValueAmountUsd?: string | null;
  @Attr() createdAt!: string;
  @Attr() updatedAt!: string;
  @BelongsTo() claimDetailOffer!: ClaimDetailOffer;

  get displayStatus(): ClaimDisplayStatus {
    switch (this.status) {
      case OfferStatus.accepted:
        return "ACCEPTED";
      case OfferStatus.canceled:
        return "CANCELED";
      case OfferStatus.countered:
        return "COUNTERED";
      case OfferStatus.sent:
        return "OFFERED";
      case OfferStatus.unsent: {
        // TODO this is probably wrong
        return "OFFERED";
      }
    }
  }
}

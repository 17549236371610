import { Model, Attr, Link } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";

@Model()
export class ServiceDocument extends ApplicationRecord {
  static jsonapiType = "services_documents";
  @Attr() contentType!: string;
  @Attr() documentType!: {
    code: string;
    label: string;
  };
  @Attr() filename!: string;
  @Attr() name!: string;
  // These two links are direct and expire
  @Link() download!: string;
  @Link() directDownload!: string;
  // These two links go through depot and don't expire
  @Link() canonical!: string;
  @Link() direct!: string;

  get displayName() {
    return (this.documentType.label);
  }
}

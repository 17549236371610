import { Model, Attr } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";

type Coordinates = {
  latitude: number;
  longitude: number;
};

@Model()
export class InventoryTracker extends ApplicationRecord {
  static jsonapiType = "inventory_trackers";

  @Attr() currentLocation!: Coordinates;
}

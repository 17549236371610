import { DepotStatus, DepotServiceTypes } from "types";

/**
 * This maps a order status to a service type which is relevant for that
 * stage of the order. For example, `LOAD_CREW_DISPATCHED: "LOAD",` means
 * when the order is in LOAD_CREW_DISPATCHED, we expect there to be a `LOAD`
 * service to be in progress.
 */
export const serviceStatusMap: { [key in DepotStatus]: DepotServiceTypes } = {
  RECEIVED: "COUNSELING",
  APPROVED: "COUNSELING",
  COUNSELING_COMPLETED: "COUNSELING",
  SURVEY_SCHEDULED: "SURVEY",
  SURVEY_IN_PROGRESS: "SURVEY",
  SURVEY_COMPLETED: "SURVEY",
  PLANNED: "SURVEY",
  PACK_CREW_DISPATCHED: "PACK",
  PACKING_STARTED: "PACK",
  PACKING_COMPLETED: "PACK",
  LOAD_CREW_DISPATCHED: "LOAD",
  LOADING_STARTED: "LOAD",
  IN_TRANSIT: "TRANSPORTATION",
  IN_STORAGE_IN_TRANSIT: "SITATORIGIN",
  IN_NON_TEMPORARY_STORAGE: "SITATDESTINATION",
  IN_STORAGE_PARTIAL: "SITFIRSTDAYATDESTINATION",
  DELIVERY_CREW_DISPATCHED: "DELIVERY",
  DELIVERY_STARTED: "DELIVERY",
  DELIVERY_COMPLETED: "DELIVERY",
  COMPLETED: "UNPACK",
  CANCELLED: "OTHER",
  UNKNOWN: "OTHER",
};

import { Attr, HasOne, Model } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { ChangeRequestId, ChangeRequestStatus } from "types";
import { OrderAddress } from "./OrderAddress";
import { formatDate } from "@movehq/datetime";

type ChangeRequestSchemaType = "address" | "date" | "yesno";

type AddressSchema = {
  street1: string;
  street2?: string;
  locality: string;
  region: string;
  postalCode: string;
};

type DateSchema = {
  date: string;
};

type YesNoSchema = {
  value: "yes" | "no";
};

type ValuesSchema<T extends ChangeRequestSchemaType> = T extends "address"
  ? AddressSchema | null
  : T extends "date"
  ? DateSchema
  : T extends "yesno"
  ? YesNoSchema
  : null;

@Model()
export class ChangeRequest<
  SchemaType extends ChangeRequestSchemaType = ChangeRequestSchemaType
> extends ApplicationRecord {
  static jsonapiType = "change_requests";

  @Attr() orderId!: string;
  @Attr() crmRecordId!: string;
  @Attr() name!: ChangeRequestId;
  @Attr() status!: ChangeRequestStatus;
  @Attr() oldValue!: ValuesSchema<SchemaType>;
  @Attr() newValue!: ValuesSchema<SchemaType>;
  @Attr() updatedAt!: Date;
  @Attr() dismissedAt!: Date;
  @HasOne() changeable!: OrderAddress;

  isPending() {
    return this.status === "SUBMITTED" || this.status === "REQUESTED";
  }

  isAddress(): this is ChangeRequest<"address"> {
    switch (this.name) {
      case "DESTINATION_ADDRESS":
      case "ORIGIN_ADDRESS":
      case "EXTRA_STOP_PICKUP":
      case "EXTRA_STOP_DELIVERY":
      case "SIT_DELIVERY_ADDRESS":
        return true;
      default:
        return false;
    }
  }

  isDate(): this is ChangeRequest<"date"> {
    switch (this.name) {
      case "PACK_DATE":
      case "LOAD_DATE":
      case "DELIVERY_DATE":
        return true;
      default:
        return false;
    }
  }

  isYesNo(): this is ChangeRequest<"yesno"> {
    switch (this.name) {
      case "STORAGE_IN_TRANSIT":
        return true;
      default:
        return false;
    }
  }

  get disallowResubmission(): boolean {
    return (
      (this.status === "REQUESTED" || this.status === "SUBMITTED") &&
      this.name === "DESTINATION_ADDRESS"
    );
  }

  get formatNewValue(): string {
    if (this.isAddress()) {
      if (this.newValue === null) {
        return `be removed`;
      }
      const { street1, street2, locality, region, postalCode } = this.newValue;
      return `${street1} ${
        street2 ? street2 + " " : ""
      }${locality} ${region} ${postalCode}`;
    }

    if (this.isDate()) {
      return formatDate(this.newValue.date, "twoDigitDayAndMonth");
    }

    if (this.isYesNo()) {
      return this.newValue.value === "yes" ? "Yes" : "No";
    }

    return "";
  }

  convertNewValueToModel(): OrderAddress | string | null {
    // @ts-ignore
    if (this.newValue && "street1" in this.newValue) {
      return new OrderAddress(this.newValue);
    }

    // @ts-ignore
    if (this.newValue && "date" in this.newValue) {
      return this.newValue.date;
    }

    return null;
  }
}

import { ApplicationRecord } from "./ApplicationRecord";
import { Attr, Model } from "spraypaint";
import { ExtraLocationType } from "../types";

// This is used on Counseling to request address changes, and is also displayed to the user on their AddressesCard
// An address change request might be for a different origin address, or for an additional stop
// Depot has :locations, a superset of :extra_stops, which we used to use here.
// Note that :locations has more fields such as rooms, which we don't need.
@Model()
export class Location extends ApplicationRecord {
  static jsonapiType = "locations";
  @Attr() type!: ExtraLocationType;
  @Attr() streetAddress!: string;
  @Attr() streetAddress2!: string;
  @Attr() city!: string;
  @Attr() state!: string;
  @Attr() postalCode!: string;
  @Attr() country!: string;
  @Attr() timezone!: string;
}

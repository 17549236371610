import { Model, Attr } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";

@Model()
export class SurveyAvailability extends ApplicationRecord {
  static jsonapiType = "survey_availabilities";
  @Attr() type!: "virtual" | "onsite";
  @Attr() startDatetime!: string;
  @Attr() endDatetime!: string;
  @Attr() availableSurveyors!: number;
  @Attr() allotedSurveyors!: number;
  @Attr() bookedSurveyors!: number;
  @Attr() timezone!: string;
}

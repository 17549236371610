import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ISO8601Date: { input: any; output: any };
  ISO8601DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type Activity = {
  __typename: "Activity";
  crmLinks?: Maybe<Array<CrmLink>>;
  /** Usually just created_at */
  dateStart: Scalars["ISO8601DateTime"]["output"];
  /** DateTime the ToDo is due. */
  dueDate: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  /** The priority of the ToDo. */
  priority?: Maybe<ActivityPriority>;
  /** The current status of the ToDo. */
  status?: Maybe<Scalars["String"]["output"]>;
  /** The subject line of the ToDO. The full description is in the CRMEntity and currently not exposed */
  subject: Scalars["String"]["output"];
};

export type ActivityCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for Activity. */
export type ActivityConnection = {
  __typename: "ActivityConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActivityEdge>>>;
  /** A list of nodes. */
  nodes: Array<Activity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ActivityEdge = {
  __typename: "ActivityEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Activity>;
};

export const enum ActivityPriority {
  High = "High",
  Low = "Low",
  Medium = "Medium",
}

export type AddressChange = {
  __typename: "AddressChange";
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  locality?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  street1?: Maybe<Scalars["String"]["output"]>;
  street2?: Maybe<Scalars["String"]["output"]>;
};

export type AddressChangeRequest = {
  newAddress?: Maybe<AddressChange>;
  oldAddress?: Maybe<AddressChange>;
};

export type AddressInput = {
  country: Scalars["String"]["input"];
  county: Scalars["String"]["input"];
  locality: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  region: Scalars["String"]["input"];
  street1: Scalars["String"]["input"];
  street2: Scalars["String"]["input"];
};

export type Agent = {
  __typename: "Agent";
  agencyName?: Maybe<Scalars["String"]["output"]>;
  agentManagerId: Scalars["Int"]["output"];
  crmLinks?: Maybe<Array<CrmLink>>;
};

export type AgentCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for Agent. */
export type AgentConnection = {
  __typename: "AgentConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgentEdge>>>;
  /** A list of nodes. */
  nodes: Array<Agent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type AgentEdge = {
  __typename: "AgentEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Agent>;
};

export type ApplicationLoginEvent = {
  __typename: "ApplicationLoginEvent";
  timestamp?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

export type Approvable = {
  id: Scalars["ID"]["output"];
};

export type ApprovalRequest = {
  __typename: "ApprovalRequest";
  amountType?: Maybe<Scalars["String"]["output"]>;
  approvable?: Maybe<Approvable>;
  approvableId?: Maybe<Scalars["ID"]["output"]>;
  approvableType?: Maybe<Scalars["String"]["output"]>;
  approvalNotes?: Maybe<Scalars["String"]["output"]>;
  changeRequestName?: Maybe<ChangeRequestType>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  crmLinks?: Maybe<Array<CrmLink>>;
  dateRequested: Scalars["ISO8601DateTime"]["output"];
  gblocLocation?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  location?: Maybe<Scalars["String"]["output"]>;
  order: Order;
  orderId?: Maybe<Scalars["ID"]["output"]>;
  requestType: ApprovalRequestType;
  requestedById?: Maybe<Scalars["ID"]["output"]>;
  requesterNotes?: Maybe<Scalars["String"]["output"]>;
  serviceType?: Maybe<ServiceType>;
  status: ApprovalRequestStatus;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type ApprovalRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for ApprovalRequest. */
export type ApprovalRequestConnection = {
  __typename: "ApprovalRequestConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ApprovalRequestEdge>>>;
  /** A list of nodes. */
  nodes: Array<ApprovalRequest>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ApprovalRequestEdge = {
  __typename: "ApprovalRequestEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<ApprovalRequest>;
};

export const enum ApprovalRequestStatus {
  Approved = "APPROVED",
  Canceled = "CANCELED",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
  Submitted = "SUBMITTED",
}

export const enum ApprovalRequestType {
  AdditionalCompensation = "ADDITIONAL_COMPENSATION",
  Government = "GOVERNMENT",
  Internal = "INTERNAL",
}

/** Autogenerated input type of AssociateUserWidget */
export type AssociateUserWidgetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The widget to associate with the user */
  widgetId: Scalars["ID"]["input"];
};

/** Autogenerated return type of AssociateUserWidget. */
export type AssociateUserWidgetPayload = {
  __typename: "AssociateUserWidgetPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** All of the widgets this user has added to their dashboard including the newest one. */
  userWidgets?: Maybe<UserWidgetConnection>;
};

/** Autogenerated return type of AssociateUserWidget. */
export type AssociateUserWidgetPayloadUserWidgetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BaseChangeRequest = Approvable &
  ChangeRequest & {
    __typename: "BaseChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newValue: Scalars["JSON"]["output"];
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type BaseChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type BaseService = Approvable &
  Service & {
    __typename: "BaseService";
    accountingStatus: ServiceAccountingStatus;
    actualEndDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    actualGrossWeight?: Maybe<Scalars["Int"]["output"]>;
    actualNetWeight?: Maybe<Scalars["Int"]["output"]>;
    actualStartDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    actualTareWeight?: Maybe<Scalars["Int"]["output"]>;
    actualWeightSource?: Maybe<Scalars["String"]["output"]>;
    approvalStatus?: Maybe<Scalars["String"]["output"]>;
    billable: Scalars["Boolean"]["output"];
    billedWeight?: Maybe<Scalars["Int"]["output"]>;
    billedWeightSource?: Maybe<Scalars["String"]["output"]>;
    completionStatus: ServiceCompletionStatus;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    createdBy?: Maybe<Scalars["ID"]["output"]>;
    crewAssignments: Array<CrewAssignment>;
    crewLocationCapturedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    crewLocationLatitude?: Maybe<Scalars["Float"]["output"]>;
    crewLocationLongitude?: Maybe<Scalars["Float"]["output"]>;
    crmLinks?: Maybe<Array<CrmLink>>;
    description?: Maybe<Scalars["String"]["output"]>;
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    displayName?: Maybe<Scalars["String"]["output"]>;
    endLocation?: Maybe<Location>;
    estimatedWeight?: Maybe<Scalars["Int"]["output"]>;
    id: Scalars["ID"]["output"];
    metadata?: Maybe<Scalars["JSON"]["output"]>;
    milmoveCode?: Maybe<Scalars["String"]["output"]>;
    milmoveId?: Maybe<Scalars["String"]["output"]>;
    moveTaskOrder?: Maybe<MoveTaskOrder>;
    name?: Maybe<Scalars["String"]["output"]>;
    notes?: Maybe<Scalars["String"]["output"]>;
    order?: Maybe<Order>;
    orderId?: Maybe<Scalars["ID"]["output"]>;
    parent?: Maybe<Service>;
    parentId?: Maybe<Scalars["Int"]["output"]>;
    plannedEndDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    plannedEndDatetimeZone?: Maybe<Scalars["String"]["output"]>;
    plannedStartDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    plannedStartDatetimeZone?: Maybe<Scalars["String"]["output"]>;
    possibleEndDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    possibleStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    preferredStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    reason?: Maybe<Scalars["String"]["output"]>;
    serviceProvider?: Maybe<ServiceProvider>;
    serviceProviderName?: Maybe<Scalars["String"]["output"]>;
    serviceProviderPhoneNumber?: Maybe<Scalars["String"]["output"]>;
    serviceType: ServiceType;
    startLocation?: Maybe<Location>;
    status: ServiceStatus;
    subtype?: Maybe<ServiceSubtype>;
    type: ServiceType;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type BaseServiceCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for BaseService. */
export type BaseServiceConnection = {
  __typename: "BaseServiceConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BaseServiceEdge>>>;
  /** A list of nodes. */
  nodes: Array<BaseService>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type BaseServiceEdge = {
  __typename: "BaseServiceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<BaseService>;
};

/** Autogenerated input type of CancelChangeRequest */
export type CancelChangeRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of CancelChangeRequest. */
export type CancelChangeRequestPayload = {
  __typename: "CancelChangeRequestPayload";
  changeRequest?: Maybe<ChangeRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

export const enum Category {
  HouseholdGoods = "HOUSEHOLD_GOODS",
  MemberProgear = "MEMBER_PROGEAR",
  SpouseProgear = "SPOUSE_PROGEAR",
}

export type ChangeRequest = {
  approvalRequest?: Maybe<ApprovalRequest>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  newValue: Scalars["JSON"]["output"];
  oldValue: Scalars["JSON"]["output"];
  order?: Maybe<Order>;
  status: ApprovalRequestStatus;
  type?: Maybe<ChangeRequestType>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for ChangeRequest. */
export type ChangeRequestConnection = {
  __typename: "ChangeRequestConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChangeRequestEdge>>>;
  /** A list of nodes. */
  nodes: Array<ChangeRequest>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ChangeRequestEdge = {
  __typename: "ChangeRequestEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<ChangeRequest>;
};

export const enum ChangeRequestType {
  DeliveryDate = "DELIVERY_DATE",
  DestinationAddress = "DESTINATION_ADDRESS",
  ExtraStopDelivery = "EXTRA_STOP_DELIVERY",
  ExtraStopPickup = "EXTRA_STOP_PICKUP",
  LoadDate = "LOAD_DATE",
  OriginAddress = "ORIGIN_ADDRESS",
  Other = "OTHER",
  PackDate = "PACK_DATE",
  SitDeliveryAddress = "SIT_DELIVERY_ADDRESS",
  StorageInTransit = "STORAGE_IN_TRANSIT",
  Survey = "SURVEY",
}

export type Chargebackable = Claim | ClaimDetail;

export type Claim = {
  __typename: "Claim";
  acceptedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  agentOwnerId?: Maybe<Scalars["ID"]["output"]>;
  amountPaidByGovUsd?: Maybe<Scalars["Float"]["output"]>;
  amountRequestedByGovUsd?: Maybe<Scalars["Float"]["output"]>;
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars["Int"]["output"]>;
  claimChargebacks: Array<ClaimChargeback>;
  claimDetails: Array<ClaimDetail>;
  claimServices: Array<ClaimService>;
  claimType?: Maybe<Scalars["String"]["output"]>;
  closedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  createdBy?: Maybe<Scalars["String"]["output"]>;
  crmLinks?: Maybe<Array<CrmLink>>;
  customerNotes?: Maybe<Scalars["String"]["output"]>;
  customerPayoutEmail?: Maybe<Scalars["String"]["output"]>;
  customerPayoutPhone?: Maybe<Scalars["String"]["output"]>;
  daysToOffer?: Maybe<Scalars["Int"]["output"]>;
  filingDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  filingMethod?: Maybe<Scalars["String"]["output"]>;
  hasMold?: Maybe<Scalars["Boolean"]["output"]>;
  hgPaidUsd?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  inspectionFeeUsd?: Maybe<Scalars["Float"]["output"]>;
  isCatastrophicLoss?: Maybe<Scalars["Boolean"]["output"]>;
  isHardship?: Maybe<Scalars["Boolean"]["output"]>;
  isQuickClaim?: Maybe<Scalars["Boolean"]["output"]>;
  order: Order;
  orderId?: Maybe<Scalars["Int"]["output"]>;
  repairPaidUsd?: Maybe<Scalars["Float"]["output"]>;
  salvagePaidUsd?: Maybe<Scalars["Float"]["output"]>;
  status: ClaimStatus;
  totalAmountClaimedUsd?: Maybe<Scalars["Float"]["output"]>;
  transferredToMcoAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  valuationUsd?: Maybe<Scalars["Float"]["output"]>;
};

export type ClaimClaimDetailsArgs = {
  filter?: InputMaybe<ReportLogicalFilterInput>;
  sort?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ClaimCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type ClaimChargeback = {
  __typename: "ClaimChargeback";
  actualTransactionAmountUsd?: Maybe<Scalars["Float"]["output"]>;
  bulkSettlement?: Maybe<Scalars["Boolean"]["output"]>;
  chargebackable: Chargebackable;
  chargebackableId: Scalars["ID"]["output"];
  chargebackableType: Scalars["String"]["output"];
  crmLinks?: Maybe<Array<CrmLink>>;
  datePaid?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  dateSubmitted?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  itemCode?: Maybe<ClaimChargebackItemCode>;
  serviceProvider: ServiceProvider;
  serviceProviderId?: Maybe<Scalars["ID"]["output"]>;
  transactionDescription?: Maybe<Scalars["String"]["output"]>;
  transactionStatus: ClaimChargebackTransactionStatus;
};

export type ClaimChargebackCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export const enum ClaimChargebackItemCode {
  Chargeback = "CHARGEBACK",
}

export const enum ClaimChargebackTransactionStatus {
  Failed = "FAILED",
  Paid = "PAID",
  Posted = "POSTED",
  Ready = "READY",
  Void = "VOID",
}

/** The connection type for Claim. */
export type ClaimConnection = {
  __typename: "ClaimConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClaimEdge>>>;
  /** A list of nodes. */
  nodes: Array<Claim>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ClaimDetail = {
  __typename: "ClaimDetail";
  amountPaidByGovUsd?: Maybe<Scalars["Float"]["output"]>;
  amountRequestedByGovUsd?: Maybe<Scalars["Float"]["output"]>;
  claimChargebacks: Array<ClaimChargeback>;
  claimDetailOffers: Array<ClaimDetailOffer>;
  claimId: Scalars["ID"]["output"];
  createdAt: Scalars["ISO8601DateTime"]["output"];
  crmLinks?: Maybe<Array<CrmLink>>;
  depreciatedValueUsd?: Maybe<Scalars["Float"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  estimatedRepairCostUsd?: Maybe<Scalars["Float"]["output"]>;
  hasMold: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  internalNotes?: Maybe<Scalars["String"]["output"]>;
  inventoryItem?: Maybe<InventoryItem>;
  inventoryItemId?: Maybe<Scalars["ID"]["output"]>;
  isEssentialItem: Scalars["Boolean"]["output"];
  isHighValue: Scalars["Boolean"]["output"];
  itemCondition?: Maybe<Scalars["String"]["output"]>;
  manufacturerMakeModel?: Maybe<Scalars["String"]["output"]>;
  purchaseCostUsd?: Maybe<Scalars["Float"]["output"]>;
  replacementValueUsd?: Maybe<Scalars["Float"]["output"]>;
  status: ClaimDetailStatus;
  transferredToMcoAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  type: Scalars["String"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  valueEstimateUsd?: Maybe<Scalars["Float"]["output"]>;
  yearOfPurchase?: Maybe<Scalars["Int"]["output"]>;
};

export type ClaimDetailCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for ClaimDetail. */
export type ClaimDetailConnection = {
  __typename: "ClaimDetailConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClaimDetailEdge>>>;
  /** A list of nodes. */
  nodes: Array<ClaimDetail>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ClaimDetailEdge = {
  __typename: "ClaimDetailEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<ClaimDetail>;
};

export type ClaimDetailOffer = {
  __typename: "ClaimDetailOffer";
  claimDetailOfferType: ClaimDetailOfferType;
  claimDetails: Array<ClaimDetail>;
  claimPayment?: Maybe<ClaimPayment>;
  crmLinks?: Maybe<Array<CrmLink>>;
  offerScope: ClaimDetailOfferScope;
};

export type ClaimDetailOfferCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export const enum ClaimDetailOfferScope {
  Claim = "CLAIM",
  ClaimDetail = "CLAIM_DETAIL",
}

export const enum ClaimDetailOfferType {
  Cash = "CASH",
  Denied = "DENIED",
  Repair = "REPAIR",
}

export const enum ClaimDetailStatus {
  Assigned = "ASSIGNED",
  Canceled = "CANCELED",
  Closed = "CLOSED",
  Countered = "COUNTERED",
  Denied = "DENIED",
  EscalatedToPpsoJppso = "ESCALATED_TO_PPSO_JPPSO",
  Inspection = "INSPECTION",
  InProgress = "IN_PROGRESS",
  Offered = "OFFERED",
  OfferAccepted = "OFFER_ACCEPTED",
  Paid = "PAID",
  Submitted = "SUBMITTED",
  TransferredToMco = "TRANSFERRED_TO_MCO",
  Unsubmitted = "UNSUBMITTED",
}

/** An edge in a connection. */
export type ClaimEdge = {
  __typename: "ClaimEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Claim>;
};

export type ClaimPayment = {
  __typename: "ClaimPayment";
  actualTransactionAmountUsd?: Maybe<Scalars["Float"]["output"]>;
  bulkSettlement?: Maybe<Scalars["Boolean"]["output"]>;
  claimDetailOffer?: Maybe<ClaimDetailOffer>;
  crmLinks?: Maybe<Array<CrmLink>>;
  customerName?: Maybe<Scalars["String"]["output"]>;
  datePaid?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  dateSubmitted?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  itemCode?: Maybe<ClaimPaymentItemCode>;
  sentToCostpoint?: Maybe<Scalars["Boolean"]["output"]>;
  transactionDescription?: Maybe<Scalars["String"]["output"]>;
  transactionStatus?: Maybe<ClaimPaymentTransactionStatus>;
};

export type ClaimPaymentCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export const enum ClaimPaymentItemCode {
  Inspection = "INSPECTION",
  Payment = "PAYMENT",
  Repair = "REPAIR",
  Salvage = "SALVAGE",
}

export const enum ClaimPaymentTransactionStatus {
  Failed = "FAILED",
  Paid = "PAID",
  Posted = "POSTED",
  Ready = "READY",
  Void = "VOID",
}

export type ClaimService = Approvable &
  Service & {
    __typename: "ClaimService";
    accountingStatus: ServiceAccountingStatus;
    actualEndDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    actualGrossWeight?: Maybe<Scalars["Int"]["output"]>;
    actualNetWeight?: Maybe<Scalars["Int"]["output"]>;
    actualStartDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    actualTareWeight?: Maybe<Scalars["Int"]["output"]>;
    actualWeightSource?: Maybe<Scalars["String"]["output"]>;
    approvalStatus?: Maybe<Scalars["String"]["output"]>;
    billable: Scalars["Boolean"]["output"];
    billedWeight?: Maybe<Scalars["Int"]["output"]>;
    billedWeightSource?: Maybe<Scalars["String"]["output"]>;
    completionStatus: ServiceCompletionStatus;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    createdBy?: Maybe<Scalars["ID"]["output"]>;
    crewAssignments: Array<CrewAssignment>;
    crewLocationCapturedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    crewLocationLatitude?: Maybe<Scalars["Float"]["output"]>;
    crewLocationLongitude?: Maybe<Scalars["Float"]["output"]>;
    crmLinks?: Maybe<Array<CrmLink>>;
    description?: Maybe<Scalars["String"]["output"]>;
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    displayName?: Maybe<Scalars["String"]["output"]>;
    endLocation?: Maybe<Location>;
    estimatedWeight?: Maybe<Scalars["Int"]["output"]>;
    id: Scalars["ID"]["output"];
    metadata?: Maybe<Scalars["JSON"]["output"]>;
    milmoveCode?: Maybe<Scalars["String"]["output"]>;
    milmoveId?: Maybe<Scalars["String"]["output"]>;
    moveTaskOrder?: Maybe<MoveTaskOrder>;
    name?: Maybe<Scalars["String"]["output"]>;
    notes?: Maybe<Scalars["String"]["output"]>;
    order?: Maybe<Order>;
    orderId?: Maybe<Scalars["ID"]["output"]>;
    parent?: Maybe<Service>;
    parentId?: Maybe<Scalars["Int"]["output"]>;
    plannedEndDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    plannedEndDatetimeZone?: Maybe<Scalars["String"]["output"]>;
    plannedStartDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    plannedStartDatetimeZone?: Maybe<Scalars["String"]["output"]>;
    possibleEndDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    possibleStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    preferredStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    reason?: Maybe<Scalars["String"]["output"]>;
    serviceProvider?: Maybe<ServiceProvider>;
    serviceProviderName?: Maybe<Scalars["String"]["output"]>;
    serviceProviderPhoneNumber?: Maybe<Scalars["String"]["output"]>;
    serviceType: ServiceType;
    startLocation?: Maybe<Location>;
    status: ServiceStatus;
    subtype?: Maybe<ServiceSubtype>;
    type: ClaimServiceType;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type ClaimServiceCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export const enum ClaimServiceType {
  Claim = "CLAIM",
  Inspection = "INSPECTION",
  Repair = "REPAIR",
  Salvage = "SALVAGE",
}

export const enum ClaimStatus {
  Assigned = "ASSIGNED",
  Canceled = "CANCELED",
  Closed = "CLOSED",
  Countered = "COUNTERED",
  Denied = "DENIED",
  EscalatedToPpsoJppso = "ESCALATED_TO_PPSO_JPPSO",
  Inspection = "INSPECTION",
  InProgress = "IN_PROGRESS",
  Offered = "OFFERED",
  OfferAccepted = "OFFER_ACCEPTED",
  Paid = "PAID",
  Submitted = "SUBMITTED",
  TransferredToMco = "TRANSFERRED_TO_MCO",
  Unsubmitted = "UNSUBMITTED",
}

export type CounselingPreference = {
  __typename: "CounselingPreference";
  alcoholicBeverages?: Maybe<Scalars["Boolean"]["output"]>;
  boat?: Maybe<Scalars["Boolean"]["output"]>;
  consumables?: Maybe<Scalars["Boolean"]["output"]>;
  crmLinks?: Maybe<Array<CrmLink>>;
  firearm?: Maybe<Scalars["Boolean"]["output"]>;
  gunSafe?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  mobileHome?: Maybe<Scalars["Boolean"]["output"]>;
  motorcycle?: Maybe<Scalars["Boolean"]["output"]>;
  order?: Order;
  orderId: Scalars["ID"]["output"];
  privatelyOwnedVehicle?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CounselingPreferenceCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated input type of CreateAddLocationChangeRequest */
export type CreateAddLocationChangeRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  locationType: Scalars["String"]["input"];
  newAddress: AddressInput;
  orderId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CreateAddLocationChangeRequest. */
export type CreateAddLocationChangeRequestPayload = {
  __typename: "CreateAddLocationChangeRequestPayload";
  changeRequest?: Maybe<ChangeRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateDestinationAddressChangeRequest */
export type CreateDestinationAddressChangeRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  newAddress: AddressInput;
  orderId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CreateDestinationAddressChangeRequest. */
export type CreateDestinationAddressChangeRequestPayload = {
  __typename: "CreateDestinationAddressChangeRequestPayload";
  changeRequest?: Maybe<ChangeRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateRemoveLocationChangeRequest */
export type CreateRemoveLocationChangeRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  locationId: Scalars["ID"]["input"];
  orderId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CreateRemoveLocationChangeRequest. */
export type CreateRemoveLocationChangeRequestPayload = {
  __typename: "CreateRemoveLocationChangeRequestPayload";
  changeRequest?: Maybe<ChangeRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of CreateReportConfig */
export type CreateReportConfigInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  columns: Array<Scalars["String"]["input"]>;
  default?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  owners: Array<OwnerInput>;
  resourceName: Scalars["String"]["input"];
  sort?: InputMaybe<Array<Scalars["String"]["input"]>>;
  subColumns?: InputMaybe<ReportConfigSubColumnsInput>;
  type: ReportConfigType;
};

export type CreateReportConfigOutput = {
  __typename: "CreateReportConfigOutput";
  reportConfig?: Maybe<ReportConfig>;
};

/** Autogenerated input type of CreateSITExtension */
export type CreateSitExtensionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  daysRequested: Scalars["Int"]["input"];
  notes: Scalars["String"]["input"];
  reason: SitExtensionReason;
  serviceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CreateSITExtension. */
export type CreateSitExtensionPayload = {
  __typename: "CreateSITExtensionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  sitExtension?: Maybe<SitExtension>;
};

/** Autogenerated input type of CreateServiceProviderCostSchedule */
export type CreateServiceProviderCostScheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  effectiveDate: Scalars["ISO8601Date"]["input"];
  rate: Scalars["Float"]["input"];
  rateType: ServiceProviderCostScheduleRate;
  serviceProviderId: Scalars["Int"]["input"];
  serviceType: Scalars["String"]["input"];
};

/** Autogenerated input type of CreateSurveyAppointment */
export type CreateSurveyAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  surveyAvailabilityId?: InputMaybe<Scalars["ID"]["input"]>;
  surveyServiceId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated input type of CreateUpdateLocationChangeRequest */
export type CreateUpdateLocationChangeRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  locationId: Scalars["ID"]["input"];
  newAddress: AddressInput;
  orderId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CreateUpdateLocationChangeRequest. */
export type CreateUpdateLocationChangeRequestPayload = {
  __typename: "CreateUpdateLocationChangeRequestPayload";
  changeRequest?: Maybe<ChangeRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

export type CrewAssignment = {
  __typename: "CrewAssignment";
  asset: Employee;
  crmLinks?: Maybe<Array<CrmLink>>;
  lead?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CrewAssignmentCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type CrmLink = {
  __typename: "CrmLink";
  field: Scalars["String"]["output"];
  link: Scalars["String"]["output"];
};

export type Customer = {
  __typename: "Customer";
  crmLinks?: Maybe<Array<CrmLink>>;
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
};

export type CustomerCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type CustomerSatisfactionSurveyResult = {
  __typename: "CustomerSatisfactionSurveyResult";
  deliveryScore?: Maybe<Scalars["Float"]["output"]>;
  loadScore?: Maybe<Scalars["Float"]["output"]>;
  packScore?: Maybe<Scalars["Float"]["output"]>;
};

export type DateChange = {
  __typename: "DateChange";
  date?: Maybe<Scalars["ISO8601Date"]["output"]>;
};

export type DateChangeRequest = {
  newDate?: Maybe<DateChange>;
  oldDate?: Maybe<DateChange>;
};

/** Autogenerated input type of DeleteReportConfig */
export type DeleteReportConfigInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type DeleteReportConfigOutput = {
  __typename: "DeleteReportConfigOutput";
  reportConfig?: Maybe<ReportConfig>;
};

export type DeliveryDateChangeRequest = Approvable &
  ChangeRequest &
  DateChangeRequest & {
    __typename: "DeliveryDateChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newDate?: Maybe<DateChange>;
    newValue: Scalars["JSON"]["output"];
    oldDate?: Maybe<DateChange>;
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type DeliveryDateChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type DestinationAddressChangeRequest = AddressChangeRequest &
  Approvable &
  ChangeRequest & {
    __typename: "DestinationAddressChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newAddress?: Maybe<AddressChange>;
    newValue: Scalars["JSON"]["output"];
    oldAddress?: Maybe<AddressChange>;
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type DestinationAddressChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export const enum DimensionUnit {
  Centimeters = "CENTIMETERS",
  Feet = "FEET",
  Inches = "INCHES",
  Meters = "METERS",
}

/** Autogenerated input type of DisassociateUserWidget */
export type DisassociateUserWidgetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The widget to disassociate from the user */
  widgetId: Scalars["ID"]["input"];
};

/** Autogenerated return type of DisassociateUserWidget. */
export type DisassociateUserWidgetPayload = {
  __typename: "DisassociateUserWidgetPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** All of the widgets this user has added to their dashboard now without the disassociated one. */
  userWidgets?: Maybe<UserWidgetConnection>;
};

/** Autogenerated return type of DisassociateUserWidget. */
export type DisassociateUserWidgetPayloadUserWidgetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Autogenerated input type of DismissChangeRequest */
export type DismissChangeRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of DismissChangeRequest. */
export type DismissChangeRequestPayload = {
  __typename: "DismissChangeRequestPayload";
  changeRequest?: Maybe<ChangeRequest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

export type Employee = {
  __typename: "Employee";
  attachments?: Maybe<Array<EmployeeAttachment>>;
  crmLinks?: Maybe<Array<CrmLink>>;
  displayName: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
};

export type EmployeeCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type EmployeeAttachment = {
  __typename: "EmployeeAttachment";
  crmLinks?: Maybe<Array<CrmLink>>;
  url: Scalars["String"]["output"];
};

export type EmployeeAttachmentCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Entitlement = {
  __typename: "Entitlement";
  branch?: Maybe<Scalars["String"]["output"]>;
  crmLinks?: Maybe<Array<CrmLink>>;
  dependentsAuthorized?: Maybe<Scalars["Boolean"]["output"]>;
  gunSafeWeight?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  nonTemporaryStorage?: Maybe<Scalars["Boolean"]["output"]>;
  order: Order;
  organizationalClothingAndIndividualEquipment?: Maybe<
    Scalars["Boolean"]["output"]
  >;
  payGrade?: Maybe<Scalars["String"]["output"]>;
  privatelyOwnedVehicle?: Maybe<Scalars["Boolean"]["output"]>;
  proGearWeight?: Maybe<Scalars["Int"]["output"]>;
  proGearWeightSpouse?: Maybe<Scalars["Int"]["output"]>;
  rank?: Maybe<Scalars["String"]["output"]>;
  rankAbbreviation?: Maybe<Scalars["String"]["output"]>;
  requiredMedicalEquipmentWeight?: Maybe<Scalars["Int"]["output"]>;
  storageInTransit?: Maybe<Scalars["Int"]["output"]>;
  totalDependents?: Maybe<Scalars["Int"]["output"]>;
  totalWeight?: Maybe<Scalars["Int"]["output"]>;
  transcomId?: Maybe<Scalars["String"]["output"]>;
  userPreferenceAlcoholicBeverages?: Maybe<Scalars["Boolean"]["output"]>;
  userPreferenceBoat?: Maybe<Scalars["Boolean"]["output"]>;
  userPreferenceConsumables?: Maybe<Scalars["Boolean"]["output"]>;
  userPreferenceFirearm?: Maybe<Scalars["Boolean"]["output"]>;
  userPreferenceGunSafe?: Maybe<Scalars["Boolean"]["output"]>;
  userPreferenceMobileHome?: Maybe<Scalars["Boolean"]["output"]>;
  userPreferenceMotorcycle?: Maybe<Scalars["Boolean"]["output"]>;
  userPreferencePrivatelyOwnedVehicle?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EntitlementCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExtraStopDeliveryChangeRequest = AddressChangeRequest &
  Approvable &
  ChangeRequest &
  LocationChangeRequest & {
    __typename: "ExtraStopDeliveryChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newAddress?: Maybe<AddressChange>;
    newLocation?: Maybe<LocationChange>;
    newValue: Scalars["JSON"]["output"];
    oldAddress?: Maybe<AddressChange>;
    oldLocation?: Maybe<LocationChange>;
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type ExtraStopDeliveryChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExtraStopPickupChangeRequest = AddressChangeRequest &
  Approvable &
  ChangeRequest &
  LocationChangeRequest & {
    __typename: "ExtraStopPickupChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newAddress?: Maybe<AddressChange>;
    newLocation?: Maybe<LocationChange>;
    newValue: Scalars["JSON"]["output"];
    oldAddress?: Maybe<AddressChange>;
    oldLocation?: Maybe<LocationChange>;
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type ExtraStopPickupChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GlobalOwner = {
  __typename: "GlobalOwner";
  name?: Maybe<Scalars["String"]["output"]>;
};

export type HubUser = {
  __typename: "HubUser";
  applicationLoginEvents?: Maybe<Array<ApplicationLoginEvent>>;
  customerUuid?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  externalCustomerRef?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  mhqUserId?: Maybe<Scalars["Int"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type InventoryItem = {
  __typename: "InventoryItem";
  caliberOrGauge?: Maybe<Scalars["String"]["output"]>;
  cartonContentPosition?: Maybe<Scalars["Int"]["output"]>;
  category?: Maybe<Category>;
  claimDetails: ClaimDetail;
  comments?: Maybe<Scalars["String"]["output"]>;
  crmLinks?: Maybe<Array<CrmLink>>;
  cube?: Maybe<Scalars["Float"]["output"]>;
  descriptiveSymbols?: Maybe<Array<Scalars["String"]["output"]>>;
  dimensionUnitType?: Maybe<DimensionUnit>;
  height?: Maybe<Scalars["Int"]["output"]>;
  highValueCost?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  isoCode?: Maybe<Scalars["String"]["output"]>;
  itemName?: Maybe<Scalars["String"]["output"]>;
  length?: Maybe<Scalars["Int"]["output"]>;
  lossOrDamageNotice?: Maybe<LossOrDamageNotice>;
  lotNumber?: Maybe<Scalars["String"]["output"]>;
  modelNumber?: Maybe<Scalars["String"]["output"]>;
  order: Order;
  orderId?: Maybe<Scalars["ID"]["output"]>;
  originLocationId?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  serialNumber?: Maybe<Scalars["String"]["output"]>;
  status: Status;
  statusReason?: Maybe<Scalars["String"]["output"]>;
  tagColor?: Maybe<TagColor>;
  tagNumber?: Maybe<Scalars["String"]["output"]>;
  weight?: Maybe<Scalars["Int"]["output"]>;
  weightType?: Maybe<Weight>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type InventoryItemCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type LoadDateChangeRequest = Approvable &
  ChangeRequest &
  DateChangeRequest & {
    __typename: "LoadDateChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newDate?: Maybe<DateChange>;
    newValue: Scalars["JSON"]["output"];
    oldDate?: Maybe<DateChange>;
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type LoadDateChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Location = {
  __typename: "Location";
  changeRequests: Array<ChangeRequest>;
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  crmLinks?: Maybe<Array<CrmLink>>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locality?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  street1?: Maybe<Scalars["String"]["output"]>;
  street2?: Maybe<Scalars["String"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  type: LocationType;
};

export type LocationCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type LocationChange = {
  __typename: "LocationChange";
  locationId?: Maybe<Scalars["ID"]["output"]>;
};

export type LocationChangeRequest = {
  newLocation?: Maybe<LocationChange>;
  oldLocation?: Maybe<LocationChange>;
};

export const enum LocationType {
  Allowancedestinationlocation = "ALLOWANCEDESTINATIONLOCATION",
  Allowanceoriginlocation = "ALLOWANCEORIGINLOCATION",
  Cage = "CAGE",
  Destinationdutylocation = "DESTINATIONDUTYLOCATION",
  Destinationlocation = "DESTINATIONLOCATION",
  Extradropofflocation = "EXTRADROPOFFLOCATION",
  Extrapickuplocation = "EXTRAPICKUPLOCATION",
  Floor = "FLOOR",
  Location = "LOCATION",
  Miscellaneous = "MISCELLANEOUS",
  Origindutylocation = "ORIGINDUTYLOCATION",
  Originlocation = "ORIGINLOCATION",
  Rack = "RACK",
  Room = "ROOM",
  Sitdeliverylocation = "SITDELIVERYLOCATION",
  Storagelocation = "STORAGELOCATION",
  Trailer = "TRAILER",
  Vault = "VAULT",
  Warehouse = "WAREHOUSE",
}

export type LossOrDamageNotice = {
  __typename: "LossOrDamageNotice";
  createdBy: LossOrDamageNoticeCreatedBy;
  crmLinks?: Maybe<Array<CrmLink>>;
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  inventoryItem: InventoryItem;
  noticeType: LossOrDamageNoticeNotice;
  reportedAt: Scalars["ISO8601DateTime"]["output"];
};

export type LossOrDamageNoticeCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export const enum LossOrDamageNoticeCreatedBy {
  Crew = "CREW",
  Customer = "CUSTOMER",
}

export const enum LossOrDamageNoticeNotice {
  Damaged = "DAMAGED",
  Missing = "MISSING",
}

export type MoveTaskOrder = {
  __typename: "MoveTaskOrder";
  availableToPrimeAt?: Maybe<Scalars["String"]["output"]>;
  crmLinks?: Maybe<Array<CrmLink>>;
  id: Scalars["ID"]["output"];
  milmoveId: Scalars["ID"]["output"];
  milmoveMoveCode?: Maybe<Scalars["String"]["output"]>;
  milmoveOrderId?: Maybe<Scalars["String"]["output"]>;
  milmoveReferenceId?: Maybe<Scalars["String"]["output"]>;
  orders: Array<Order>;
  shipments: Array<Order>;
};

export type MoveTaskOrderCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for MoveTaskOrder. */
export type MoveTaskOrderConnection = {
  __typename: "MoveTaskOrderConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MoveTaskOrderEdge>>>;
  /** A list of nodes. */
  nodes: Array<MoveTaskOrder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type MoveTaskOrderEdge = {
  __typename: "MoveTaskOrderEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<MoveTaskOrder>;
};

export const enum MoveTrackerKey {
  ArrivedAtStorageDestination = "ARRIVED_AT_STORAGE_DESTINATION",
  ArrivedAtStorageDestinationPartial = "ARRIVED_AT_STORAGE_DESTINATION_PARTIAL",
  ArrivedAtStorageOrigin = "ARRIVED_AT_STORAGE_ORIGIN",
  Delivery = "DELIVERY",
  FinalDelivery = "FINAL_DELIVERY",
  Load = "LOAD",
  Pack = "PACK",
  PartialDelivery = "PARTIAL_DELIVERY",
  SitDelivery = "SIT_DELIVERY",
  Transportation = "TRANSPORTATION",
}

export type MoveTrackerStep = {
  __typename: "MoveTrackerStep";
  key: MoveTrackerKey;
  service?: Maybe<BaseService>;
  stepStatus: MoveTrackerStepStatus;
};

/** The connection type for MoveTrackerStep. */
export type MoveTrackerStepConnection = {
  __typename: "MoveTrackerStepConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MoveTrackerStepEdge>>>;
  /** A list of nodes. */
  nodes: Array<MoveTrackerStep>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type MoveTrackerStepEdge = {
  __typename: "MoveTrackerStepEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<MoveTrackerStep>;
};

export const enum MoveTrackerStepStatus {
  Completed = "COMPLETED",
  Current = "CURRENT",
  Unstarted = "UNSTARTED",
}

export type Mutation = {
  __typename: "Mutation";
  /** Associates a new Widget to a User */
  associateUserWidget?: Maybe<AssociateUserWidgetPayload>;
  cancelChangeRequest?: Maybe<CancelChangeRequestPayload>;
  createAddLocationChangeRequest?: Maybe<CreateAddLocationChangeRequestPayload>;
  /** @deprecated Use createUpdateLocationChangeRequest */
  createDestinationAddressChangeRequest?: Maybe<CreateDestinationAddressChangeRequestPayload>;
  /** @deprecated Use createUpdateLocationChangeRequest */
  createLocationAddressChangeRequest?: Maybe<CreateUpdateLocationChangeRequestPayload>;
  createRemoveLocationChangeRequest?: Maybe<CreateRemoveLocationChangeRequestPayload>;
  /** Creates a new report config */
  createReportConfig?: Maybe<CreateReportConfigOutput>;
  createServiceProviderCostSchedule?: Maybe<ServiceProviderCostScheduleOutput>;
  createSitExtension?: Maybe<CreateSitExtensionPayload>;
  /** Creates a new survey appointment */
  createSurveyAppointment?: Maybe<SurveyAppointmentOutput>;
  createUpdateLocationChangeRequest?: Maybe<CreateUpdateLocationChangeRequestPayload>;
  /** Updates a report config */
  deleteReportConfig?: Maybe<DeleteReportConfigOutput>;
  /** Removes a new Widget <-> User association */
  disassociateUserWidget?: Maybe<DisassociateUserWidgetPayload>;
  dismissChangeRequest?: Maybe<DismissChangeRequestPayload>;
  resubmitSitGovernmentApproval?: Maybe<ResubmitSitGovernmentApprovalPayload>;
  /** Returns inventory back to SIT storage */
  returnInventoryToStorage?: Maybe<ReturnInventoryToStorageOutput>;
  switchSurveyToVirtual?: Maybe<SwitchSurveyToVirtualOutput>;
  /** Updates a report config */
  updateReportConfig?: Maybe<UpdateReportConfigOutput>;
  /** Upsert counseling preferences of an order */
  upsertCounselingPreference?: Maybe<UpsertCounselingPreferencePayload>;
};

export type MutationAssociateUserWidgetArgs = {
  input: AssociateUserWidgetInput;
};

export type MutationCancelChangeRequestArgs = {
  input: CancelChangeRequestInput;
};

export type MutationCreateAddLocationChangeRequestArgs = {
  input: CreateAddLocationChangeRequestInput;
};

export type MutationCreateDestinationAddressChangeRequestArgs = {
  input: CreateDestinationAddressChangeRequestInput;
};

export type MutationCreateLocationAddressChangeRequestArgs = {
  input: CreateUpdateLocationChangeRequestInput;
};

export type MutationCreateRemoveLocationChangeRequestArgs = {
  input: CreateRemoveLocationChangeRequestInput;
};

export type MutationCreateReportConfigArgs = {
  input: CreateReportConfigInput;
};

export type MutationCreateServiceProviderCostScheduleArgs = {
  input: CreateServiceProviderCostScheduleInput;
};

export type MutationCreateSitExtensionArgs = {
  input: CreateSitExtensionInput;
};

export type MutationCreateSurveyAppointmentArgs = {
  input: CreateSurveyAppointmentInput;
};

export type MutationCreateUpdateLocationChangeRequestArgs = {
  input: CreateUpdateLocationChangeRequestInput;
};

export type MutationDeleteReportConfigArgs = {
  input: DeleteReportConfigInput;
};

export type MutationDisassociateUserWidgetArgs = {
  input: DisassociateUserWidgetInput;
};

export type MutationDismissChangeRequestArgs = {
  input: DismissChangeRequestInput;
};

export type MutationResubmitSitGovernmentApprovalArgs = {
  input: ResubmitSitGovernmentApprovalInput;
};

export type MutationReturnInventoryToStorageArgs = {
  input: ReturnInventoryToStorageInput;
};

export type MutationSwitchSurveyToVirtualArgs = {
  input: SwitchSurveyToVirtualInput;
};

export type MutationUpdateReportConfigArgs = {
  input: UpdateReportConfigInput;
};

export type MutationUpsertCounselingPreferenceArgs = {
  input: UpsertCounselingPreferenceInput;
};

export type Order = {
  __typename: "Order";
  actualDeliveryDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  actualLoadDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  actualPackDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  actualWeight?: Maybe<Scalars["Int"]["output"]>;
  actualWeightSource?: Maybe<OrderWeightSource>;
  approvalRequests: Array<ApprovalRequest>;
  assignedUser?: Maybe<User>;
  auction?: Maybe<Scalars["String"]["output"]>;
  auctionType?: Maybe<Scalars["String"]["output"]>;
  billedWeight?: Maybe<Scalars["Int"]["output"]>;
  billingType?: Maybe<Scalars["String"]["output"]>;
  bolNumber?: Maybe<Scalars["String"]["output"]>;
  bookedDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  businessLine: Scalars["String"]["output"];
  commodities?: Maybe<Scalars["String"]["output"]>;
  contactId?: Maybe<Scalars["Int"]["output"]>;
  conusOconus?: Maybe<OrderConusOconus>;
  counselingCompletedDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  counselingStatus: Scalars["String"]["output"];
  crmLinks?: Maybe<Array<CrmLink>>;
  customer?: Maybe<Customer>;
  customerHubUser?: Maybe<HubUser>;
  customerSatisfactionSurveyResult: CustomerSatisfactionSurveyResult;
  customerUuid?: Maybe<Scalars["String"]["output"]>;
  deliveryDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  deliveryToDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  destInternationalPortCode?: Maybe<Scalars["String"]["output"]>;
  destServiceArea?: Maybe<Scalars["String"]["output"]>;
  destination: OrderAddress;
  destinationAddress1?: Maybe<Scalars["String"]["output"]>;
  destinationAddress2?: Maybe<Scalars["String"]["output"]>;
  destinationCity?: Maybe<Scalars["String"]["output"]>;
  destinationContact?: Maybe<Scalars["String"]["output"]>;
  destinationCountry?: Maybe<Scalars["String"]["output"]>;
  destinationCounty?: Maybe<Scalars["String"]["output"]>;
  destinationDescription?: Maybe<Scalars["String"]["output"]>;
  destinationPhone1?: Maybe<Scalars["String"]["output"]>;
  destinationPhone2?: Maybe<Scalars["String"]["output"]>;
  destinationState?: Maybe<Scalars["String"]["output"]>;
  destinationZip?: Maybe<Scalars["String"]["output"]>;
  destinationZone?: Maybe<Scalars["String"]["output"]>;
  entitlement?: Maybe<Entitlement>;
  estimatedCube?: Maybe<Scalars["Int"]["output"]>;
  estimatedOverageCost?: Maybe<Scalars["Float"]["output"]>;
  estimatedWeight?: Maybe<Scalars["Int"]["output"]>;
  gblNumber?: Maybe<Scalars["String"]["output"]>;
  gblRevision?: Maybe<Scalars["Int"]["output"]>;
  grossWeight?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  initialGrossWeight?: Maybe<Scalars["Int"]["output"]>;
  initialNetWeight?: Maybe<Scalars["Int"]["output"]>;
  initialTareWeight?: Maybe<Scalars["Int"]["output"]>;
  inventoryItems: Array<InventoryItem>;
  loadDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  loadToDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  locations: Array<Location>;
  milmoveMtoId?: Maybe<Scalars["String"]["output"]>;
  milmoveMtoMovecode?: Maybe<Scalars["String"]["output"]>;
  milmoveShipmentId?: Maybe<Scalars["String"]["output"]>;
  moveTaskOrder?: Maybe<MoveTaskOrder>;
  mtoOrderType?: Maybe<Scalars["String"]["output"]>;
  netWeight?: Maybe<Scalars["Int"]["output"]>;
  ntsWeight?: Maybe<Scalars["Int"]["output"]>;
  number: Scalars["String"]["output"];
  origin: OrderAddress;
  originAddress1?: Maybe<Scalars["String"]["output"]>;
  originAddress2?: Maybe<Scalars["String"]["output"]>;
  originCity?: Maybe<Scalars["String"]["output"]>;
  originContact?: Maybe<Scalars["String"]["output"]>;
  originCountry?: Maybe<Scalars["String"]["output"]>;
  originCounty?: Maybe<Scalars["String"]["output"]>;
  originDescription?: Maybe<Scalars["String"]["output"]>;
  originInternationalPortCode?: Maybe<Scalars["String"]["output"]>;
  originPhone1?: Maybe<Scalars["String"]["output"]>;
  originPhone2?: Maybe<Scalars["String"]["output"]>;
  originServiceArea?: Maybe<Scalars["String"]["output"]>;
  originState?: Maybe<Scalars["String"]["output"]>;
  originZip?: Maybe<Scalars["String"]["output"]>;
  originZone?: Maybe<Scalars["String"]["output"]>;
  packDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  packToDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  parentId?: Maybe<Scalars["String"]["output"]>;
  personalMove?: Maybe<Scalars["Boolean"]["output"]>;
  pieceCount?: Maybe<Scalars["Int"]["output"]>;
  plannedDeliveryDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  plannedLoadDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  plannedPackDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  preferredDeliveryDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  preferredLoadDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  preferredPackDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  proGearWeight?: Maybe<Scalars["Int"]["output"]>;
  rdd?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  rddSource?: Maybe<RddSource>;
  receivedDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  referenceId?: Maybe<Scalars["String"]["output"]>;
  reweighGrossWeight?: Maybe<Scalars["Int"]["output"]>;
  reweighNetWeight?: Maybe<Scalars["Int"]["output"]>;
  reweighTareWeight?: Maybe<Scalars["Int"]["output"]>;
  services: Array<Service>;
  shipmentType?: Maybe<Scalars["String"]["output"]>;
  sitInDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  sitOutDate?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  sitRequested?: Maybe<Scalars["Boolean"]["output"]>;
  sitWeight?: Maybe<Scalars["Int"]["output"]>;
  spouseProGearWeight?: Maybe<Scalars["Int"]["output"]>;
  status: OrderStatus;
  tareWeight?: Maybe<Scalars["Int"]["output"]>;
  totalEstimatedWeight?: Maybe<Scalars["Int"]["output"]>;
  tripId?: Maybe<Scalars["Int"]["output"]>;
};

export type OrderCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrderAddress = {
  __typename: "OrderAddress";
  country?: Maybe<Scalars["String"]["output"]>;
  county?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  locality?: Maybe<Scalars["String"]["output"]>;
  phones?: Maybe<Array<Scalars["String"]["output"]>>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  street1?: Maybe<Scalars["String"]["output"]>;
  street2?: Maybe<Scalars["String"]["output"]>;
};

/** Order conus oconus enum */
export const enum OrderConusOconus {
  Conus = "CONUS",
  Oconus = "OCONUS",
}

/** Order status enum */
export const enum OrderStatus {
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  CounselingCompleted = "COUNSELING_COMPLETED",
  DeliveryCompleted = "DELIVERY_COMPLETED",
  DeliveryCrewDispatched = "DELIVERY_CREW_DISPATCHED",
  DeliveryStarted = "DELIVERY_STARTED",
  InNonTemporaryStorage = "IN_NON_TEMPORARY_STORAGE",
  InStorageInTransit = "IN_STORAGE_IN_TRANSIT",
  InStoragePartial = "IN_STORAGE_PARTIAL",
  InTransit = "IN_TRANSIT",
  LoadingStarted = "LOADING_STARTED",
  LoadCrewDispatched = "LOAD_CREW_DISPATCHED",
  PackingCompleted = "PACKING_COMPLETED",
  PackingStarted = "PACKING_STARTED",
  PackCrewDispatched = "PACK_CREW_DISPATCHED",
  Planned = "PLANNED",
  Received = "RECEIVED",
  SurveyCompleted = "SURVEY_COMPLETED",
  SurveyInProgress = "SURVEY_IN_PROGRESS",
  SurveyScheduled = "SURVEY_SCHEDULED",
}

/** Order weight source enum */
export const enum OrderWeightSource {
  Initial = "INITIAL",
  Reweigh = "REWEIGH",
}

export type OriginAddressChangeRequest = AddressChangeRequest &
  Approvable &
  ChangeRequest & {
    __typename: "OriginAddressChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newAddress?: Maybe<AddressChange>;
    newValue: Scalars["JSON"]["output"];
    oldAddress?: Maybe<AddressChange>;
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type OriginAddressChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** Owner type union */
export type Owner = Agent | GlobalOwner | User | Vanline;

/** Owners input type */
export type OwnerInput = {
  ownerId?: InputMaybe<Scalars["ID"]["input"]>;
  ownerType: OwnerTypeEnum;
};

/** Owner type enum */
export const enum OwnerTypeEnum {
  Agent = "AGENT",
  Global = "GLOBAL",
  User = "USER",
  Vanline = "VANLINE",
}

export type PackDateChangeRequest = Approvable &
  ChangeRequest &
  DateChangeRequest & {
    __typename: "PackDateChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newDate?: Maybe<DateChange>;
    newValue: Scalars["JSON"]["output"];
    oldDate?: Maybe<DateChange>;
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type PackDateChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export const enum PolicyPermissions {
  Create = "CREATE",
  Destroy = "DESTROY",
  Export = "EXPORT",
  Import = "IMPORT",
  Index = "INDEX",
  Show = "SHOW",
  Update = "UPDATE",
}

export type PpmCostCalculations = {
  __typename: "PpmCostCalculations";
  gcc?: Maybe<Scalars["Float"]["output"]>;
  totalAdvanceOperatingAllowance?: Maybe<Scalars["Float"]["output"]>;
  totalIncentive?: Maybe<Scalars["Float"]["output"]>;
};

export type Query = {
  __typename: "Query";
  activities: ActivityConnection;
  agentManagers?: Maybe<AgentConnection>;
  approvalRequest?: Maybe<ApprovalRequest>;
  approvalRequests?: Maybe<ApprovalRequestConnection>;
  baseServices?: Maybe<BaseServiceConnection>;
  changeRequest?: Maybe<ChangeRequest>;
  changeRequests?: Maybe<ChangeRequestConnection>;
  claimDetails?: Maybe<ClaimDetailConnection>;
  claims?: Maybe<ClaimConnection>;
  counselingPreference?: Maybe<CounselingPreference>;
  /** Get the first order/shipment that needs counseling */
  currentShipment?: Maybe<Order>;
  moveTaskOrders: MoveTaskOrderConnection;
  moveTrackerSteps: MoveTrackerStepConnection;
  order?: Maybe<Order>;
  pendingSurveyService?: Maybe<SurveyService>;
  policyPermissions?: Maybe<Array<PolicyPermissions>>;
  ppmCostCalculations: PpmCostCalculations;
  reportConfigs: ReportConfigConnection;
  reportSchema?: Maybe<ReportSchema>;
  service?: Maybe<Service>;
  services: ServiceConnection;
  surveyAvailabilities: SurveyAvailabilityConnection;
  userProfilePreferences?: Maybe<UserProfilePreferences>;
  /** All of the widgets this user has added to their dashboard */
  userWidgets?: Maybe<UserWidgetConnection>;
  vanlineManagers?: Maybe<VanlineConnection>;
  /** All of the supported widgets */
  widgets?: Maybe<WidgetConnection>;
};

export type QueryActivitiesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  complete?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  overdue?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryAgentManagersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ReportLogicalFilterInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryApprovalRequestArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryApprovalRequestsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ReportLogicalFilterInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryBaseServicesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ReportLogicalFilterInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryChangeRequestArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryChangeRequestsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryClaimDetailsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ReportLogicalFilterInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryClaimsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ReportLogicalFilterInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryCounselingPreferenceArgs = {
  orderId: Scalars["ID"]["input"];
};

export type QueryMoveTaskOrdersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryMoveTrackerStepsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  orderId: Scalars["ID"]["input"];
};

export type QueryOrderArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPendingSurveyServiceArgs = {
  orderId: Scalars["ID"]["input"];
};

export type QueryPolicyPermissionsArgs = {
  resourceName: Scalars["String"]["input"];
};

export type QueryPpmCostCalculationsArgs = {
  orderId: Scalars["ID"]["input"];
  weight: Scalars["Int"]["input"];
};

export type QueryReportConfigsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  resourceName?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryServiceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryServicesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QuerySurveyAvailabilitiesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["ISO8601Date"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  orderId: Scalars["ID"]["input"];
  startDate: Scalars["ISO8601Date"]["input"];
  surveyType: Scalars["String"]["input"];
};

export type QueryUserProfilePreferencesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryUserWidgetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryVanlineManagersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ReportLogicalFilterInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryWidgetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** RDD Source enum */
export const enum RddSource {
  Internal = "INTERNAL",
  Milmove = "MILMOVE",
}

export type ReportComparisonFilterInput = {
  field: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReportConfig = {
  __typename: "ReportConfig";
  columns: Array<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  createdBy?: Maybe<User>;
  crmLinks?: Maybe<Array<CrmLink>>;
  default: Scalars["Boolean"]["output"];
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  filter?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  owners: Array<Owner>;
  resourceName: Scalars["String"]["output"];
  sort?: Maybe<Array<Scalars["String"]["output"]>>;
  subColumns?: Maybe<ReportConfigSubColumns>;
  type: ReportConfigType;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type ReportConfigCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for ReportConfig. */
export type ReportConfigConnection = {
  __typename: "ReportConfigConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReportConfigEdge>>>;
  /** A list of nodes. */
  nodes: Array<ReportConfig>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ReportConfigEdge = {
  __typename: "ReportConfigEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<ReportConfig>;
};

export type ReportConfigSubColumns = {
  __typename: "ReportConfigSubColumns";
  columns?: Maybe<Array<Scalars["String"]["output"]>>;
  relationship?: Maybe<Scalars["String"]["output"]>;
};

export type ReportConfigSubColumnsInput = {
  columns: Scalars["JSON"]["input"];
  relationship: Scalars["String"]["input"];
};

/** Report Config Type enum */
export const enum ReportConfigType {
  Listview = "LISTVIEW",
  Report = "REPORT",
}

export type ReportEnumValue = {
  __typename: "ReportEnumValue";
  deprecationReason?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  isDeprecated: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ReportFilterInput = {
  ALL?: InputMaybe<ReportRelationshipFilterInput>;
  AND?: InputMaybe<Array<ReportFilterInput>>;
  ANY?: InputMaybe<ReportRelationshipFilterInput>;
  CONTAIN?: InputMaybe<ReportComparisonFilterInput>;
  CURRENT_MONTH?: InputMaybe<ReportComparisonFilterInput>;
  CURRENT_WEEK?: InputMaybe<ReportComparisonFilterInput>;
  EMPTY?: InputMaybe<ReportComparisonFilterInput>;
  EQ?: InputMaybe<ReportComparisonFilterInput>;
  GT?: InputMaybe<ReportComparisonFilterInput>;
  GTE?: InputMaybe<ReportComparisonFilterInput>;
  LT?: InputMaybe<ReportComparisonFilterInput>;
  LTE?: InputMaybe<ReportComparisonFilterInput>;
  NEQ?: InputMaybe<ReportComparisonFilterInput>;
  NEXT_MONTH?: InputMaybe<ReportComparisonFilterInput>;
  NEXT_WEEK?: InputMaybe<ReportComparisonFilterInput>;
  NONE?: InputMaybe<ReportRelationshipFilterInput>;
  NOT?: InputMaybe<Array<ReportFilterInput>>;
  NOT_CONTAIN?: InputMaybe<ReportComparisonFilterInput>;
  NOT_EMPTY?: InputMaybe<ReportComparisonFilterInput>;
  OR?: InputMaybe<Array<ReportFilterInput>>;
  PREVIOUS_MONTH?: InputMaybe<ReportComparisonFilterInput>;
  PREVIOUS_WEEK?: InputMaybe<ReportComparisonFilterInput>;
  TODAY?: InputMaybe<ReportComparisonFilterInput>;
  WITHIN_LAST_DAYS?: InputMaybe<ReportComparisonFilterInput>;
  WITHIN_NEXT_DAYS?: InputMaybe<ReportComparisonFilterInput>;
};

export type ReportLogicalFilterInput = {
  AND?: InputMaybe<Array<ReportFilterInput>>;
  NOT?: InputMaybe<Array<ReportFilterInput>>;
  OR?: InputMaybe<Array<ReportFilterInput>>;
};

export type ReportOperator = {
  __typename: "ReportOperator";
  label: Scalars["String"]["output"];
  value: ReportOperatorValue;
};

export const enum ReportOperatorValue {
  And = "AND",
  Not = "NOT",
  Or = "OR",
}

export type ReportRelationshipFilterInput = {
  field: Scalars["String"]["input"];
  filter: ReportLogicalFilterInput;
};

export type ReportResource = {
  __typename: "ReportResource";
  fields: Array<ReportResourceField>;
  label: Scalars["String"]["output"];
  listQueryName: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type ReportResourceField = {
  __typename: "ReportResourceField";
  deprecationReason?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  enumValues?: Maybe<Array<ReportEnumValue>>;
  formatType: ReportResourceFormat;
  isDeprecated: Scalars["Boolean"]["output"];
  isFilterable: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  operators?: Maybe<Array<ReportResourceOperator>>;
  relationType?: Maybe<ReportResourceRelation>;
  type: Scalars["String"]["output"];
};

export const enum ReportResourceFormat {
  Currency = "CURRENCY",
  CurrencyRounded = "CURRENCY_ROUNDED",
  Date = "DATE",
  Datetime = "DATETIME",
  DatetimeWithTz = "DATETIME_WITH_TZ",
  Number = "NUMBER",
  Percent = "PERCENT",
  Text = "TEXT",
}

export type ReportResourceOperator = {
  __typename: "ReportResourceOperator";
  label: Scalars["String"]["output"];
  value: ReportResourceOperatorValue;
};

export const enum ReportResourceOperatorValue {
  All = "ALL",
  Any = "ANY",
  Contain = "CONTAIN",
  CurrentMonth = "CURRENT_MONTH",
  CurrentWeek = "CURRENT_WEEK",
  Empty = "EMPTY",
  Eq = "EQ",
  Gt = "GT",
  Gte = "GTE",
  Lt = "LT",
  Lte = "LTE",
  Neq = "NEQ",
  NextMonth = "NEXT_MONTH",
  NextWeek = "NEXT_WEEK",
  None = "NONE",
  NotContain = "NOT_CONTAIN",
  NotEmpty = "NOT_EMPTY",
  PreviousMonth = "PREVIOUS_MONTH",
  PreviousWeek = "PREVIOUS_WEEK",
  Today = "TODAY",
  WithinLastDays = "WITHIN_LAST_DAYS",
  WithinNextDays = "WITHIN_NEXT_DAYS",
}

export const enum ReportResourceRelation {
  BelongsTo = "BELONGS_TO",
  HasMany = "HAS_MANY",
  HasOne = "HAS_ONE",
}

export type ReportSchema = {
  __typename: "ReportSchema";
  operators: Array<ReportOperator>;
  resources: Array<ReportResource>;
};

/** Autogenerated input type of ResubmitSITGovernmentApproval */
export type ResubmitSitGovernmentApprovalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  serviceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of ResubmitSITGovernmentApproval. */
export type ResubmitSitGovernmentApprovalPayload = {
  __typename: "ResubmitSITGovernmentApprovalPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  service?: Maybe<Service>;
};

/** Autogenerated input type of ReturnInventoryToStorage */
export type ReturnInventoryToStorageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The list of Item IDs to return to storage. */
  inventoryItemIds: Array<Scalars["String"]["input"]>;
  /** The ID of the Delivery Service who's items will be returned to storage */
  serviceId: Scalars["ID"]["input"];
};

export type ReturnInventoryToStorageOutput = {
  __typename: "ReturnInventoryToStorageOutput";
  serviceId: Scalars["ID"]["output"];
};

export type SitDeliveryAddressChangeRequest = AddressChangeRequest &
  Approvable &
  ChangeRequest &
  LocationChangeRequest & {
    __typename: "SITDeliveryAddressChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newAddress?: Maybe<AddressChange>;
    newLocation?: Maybe<LocationChange>;
    newValue: Scalars["JSON"]["output"];
    oldAddress?: Maybe<AddressChange>;
    oldLocation?: Maybe<LocationChange>;
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type SitDeliveryAddressChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type SitExtension = Approvable & {
  __typename: "SITExtension";
  approvalRequest?: Maybe<ApprovalRequest>;
  crmLinks?: Maybe<Array<CrmLink>>;
  daysApproved?: Maybe<Scalars["Int"]["output"]>;
  daysRequested?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  milmoveId?: Maybe<Scalars["ID"]["output"]>;
  order?: Maybe<Order>;
  reason?: Maybe<SitExtensionReason>;
  service?: Maybe<Service>;
};

export type SitExtensionCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export const enum SitExtensionReason {
  AwaitingCompletionOfResidence = "AWAITING_COMPLETION_OF_RESIDENCE",
  DirectedTemporaryDuty = "DIRECTED_TEMPORARY_DUTY",
  ImpendingAssignment = "IMPENDING_ASSIGNMENT",
  NonavailabilityOfCivilianHousing = "NONAVAILABILITY_OF_CIVILIAN_HOUSING",
  Other = "OTHER",
  SeriousIllnessDependent = "SERIOUS_ILLNESS_DEPENDENT",
  SeriousIllnessMember = "SERIOUS_ILLNESS_MEMBER",
}

export type Service = {
  accountingStatus: ServiceAccountingStatus;
  actualEndDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  actualGrossWeight?: Maybe<Scalars["Int"]["output"]>;
  actualNetWeight?: Maybe<Scalars["Int"]["output"]>;
  actualStartDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  actualTareWeight?: Maybe<Scalars["Int"]["output"]>;
  actualWeightSource?: Maybe<Scalars["String"]["output"]>;
  approvalStatus?: Maybe<Scalars["String"]["output"]>;
  billable: Scalars["Boolean"]["output"];
  billedWeight?: Maybe<Scalars["Int"]["output"]>;
  billedWeightSource?: Maybe<Scalars["String"]["output"]>;
  completionStatus: ServiceCompletionStatus;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  createdBy?: Maybe<Scalars["ID"]["output"]>;
  crewAssignments: Array<CrewAssignment>;
  crewLocationCapturedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  crewLocationLatitude?: Maybe<Scalars["Float"]["output"]>;
  crewLocationLongitude?: Maybe<Scalars["Float"]["output"]>;
  crmLinks?: Maybe<Array<CrmLink>>;
  description?: Maybe<Scalars["String"]["output"]>;
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  endLocation?: Maybe<Location>;
  estimatedWeight?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  metadata?: Maybe<Scalars["JSON"]["output"]>;
  milmoveCode?: Maybe<Scalars["String"]["output"]>;
  milmoveId?: Maybe<Scalars["String"]["output"]>;
  moveTaskOrder?: Maybe<MoveTaskOrder>;
  name?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Order>;
  parent?: Maybe<Service>;
  parentId?: Maybe<Scalars["Int"]["output"]>;
  plannedEndDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  plannedEndDatetimeZone?: Maybe<Scalars["String"]["output"]>;
  plannedStartDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  plannedStartDatetimeZone?: Maybe<Scalars["String"]["output"]>;
  possibleEndDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  possibleStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  preferredStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  reason?: Maybe<Scalars["String"]["output"]>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderName?: Maybe<Scalars["String"]["output"]>;
  serviceProviderPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  serviceType: ServiceType;
  startLocation?: Maybe<Location>;
  status: ServiceStatus;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type ServiceCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export const enum ServiceAccountingStatus {
  NotReady = "NOT_READY",
  Preparing = "PREPARING",
  Ready = "READY",
}

export const enum ServiceCompletionStatus {
  Canceled = "CANCELED",
  CrewDispatched = "CREW_DISPATCHED",
  InProgress = "IN_PROGRESS",
  InReview = "IN_REVIEW",
  NotStarted = "NOT_STARTED",
  Performed = "PERFORMED",
}

/** The connection type for Service. */
export type ServiceConnection = {
  __typename: "ServiceConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ServiceEdge>>>;
  /** A list of nodes. */
  nodes: Array<Service>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ServiceEdge = {
  __typename: "ServiceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Service>;
};

export type ServiceProvider = {
  __typename: "ServiceProvider";
  city?: Maybe<Scalars["String"]["output"]>;
  claimChargebacks: Array<ClaimChargeback>;
  classification?: Maybe<ServiceProviderClassification>;
  cqiScore?: Maybe<Scalars["Float"]["output"]>;
  crmLinks?: Maybe<Array<CrmLink>>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  servicesProvided?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
};

export type ServiceProviderCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export const enum ServiceProviderClassification {
  Agent = "Agent",
  Contractor = "Contractor",
  ServiceProvider = "ServiceProvider",
  Vendor = "Vendor",
}

export type ServiceProviderCostSchedule = {
  __typename: "ServiceProviderCostSchedule";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  crmLinks?: Maybe<Array<CrmLink>>;
  effectiveDate: Scalars["ISO8601Date"]["output"];
  id: Scalars["Int"]["output"];
  rate: Scalars["Float"]["output"];
  rateType: ServiceProviderCostScheduleRate;
  serviceProviderId: Scalars["Int"]["output"];
  serviceType: Scalars["String"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type ServiceProviderCostScheduleCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type ServiceProviderCostScheduleOutput = {
  __typename: "ServiceProviderCostScheduleOutput";
  serviceProviderCostSchedule?: Maybe<ServiceProviderCostSchedule>;
};

export const enum ServiceProviderCostScheduleRate {
  Flat = "FLAT",
  PerCwt = "PER_CWT",
  PerMile = "PER_MILE",
}

/** Service status enum */
export const enum ServiceStatus {
  Canceled = "CANCELED",
  CrewAssigned = "CREW_ASSIGNED",
  CrewDispatched = "CREW_DISPATCHED",
  InProgress = "IN_PROGRESS",
  InReview = "IN_REVIEW",
  Performed = "PERFORMED",
  Planned = "PLANNED",
  ProviderAssigned = "PROVIDER_ASSIGNED",
  ReadyForAccounting = "READY_FOR_ACCOUNTING",
  Unassigned = "UNASSIGNED",
}

export const enum ServiceSubtype {
  Both = "BOTH",
  CrateAndFreight = "CRATE_AND_FREIGHT",
  Customer = "CUSTOMER",
  Government = "GOVERNMENT",
  NoTouch = "NO_TOUCH",
  Onsite = "ONSITE",
  Traditional = "TRADITIONAL",
  Virtual = "VIRTUAL",
}

export const enum ServiceType {
  ServiceAdditionalCompensation = "SERVICE_ADDITIONAL_COMPENSATION",
  ServiceClaim = "SERVICE_CLAIM",
  ServiceClaimInspection = "SERVICE_CLAIM_INSPECTION",
  ServiceClaimRepair = "SERVICE_CLAIM_REPAIR",
  ServiceClaimSalvage = "SERVICE_CLAIM_SALVAGE",
  ServiceCounseling = "SERVICE_COUNSELING",
  ServiceCrating = "SERVICE_CRATING",
  ServiceDelivery = "SERVICE_DELIVERY",
  ServiceFuelSurcharge = "SERVICE_FUEL_SURCHARGE",
  ServiceHaulAwayBoat = "SERVICE_HAUL_AWAY_BOAT",
  ServiceLoad = "SERVICE_LOAD",
  ServiceManagementFee = "SERVICE_MANAGEMENT_FEE",
  ServiceNtsPack = "SERVICE_NTS_PACK",
  ServicePack = "SERVICE_PACK",
  ServiceReweigh = "SERVICE_REWEIGH",
  ServiceShuttleAtDestination = "SERVICE_SHUTTLE_AT_DESTINATION",
  ServiceShuttleAtOrigin = "SERVICE_SHUTTLE_AT_ORIGIN",
  ServiceSitAdditionalDaysAtDestination = "SERVICE_SIT_ADDITIONAL_DAYS_AT_DESTINATION",
  ServiceSitAdditionalDaysAtOrigin = "SERVICE_SIT_ADDITIONAL_DAYS_AT_ORIGIN",
  ServiceSitDelivery = "SERVICE_SIT_DELIVERY",
  ServiceSitFirstDayAtDestination = "SERVICE_SIT_FIRST_DAY_AT_DESTINATION",
  ServiceSitFirstDayAtOrigin = "SERVICE_SIT_FIRST_DAY_AT_ORIGIN",
  ServiceSitPickup = "SERVICE_SIT_PICKUP",
  ServiceSurvey = "SERVICE_SURVEY",
  ServiceThirdParty = "SERVICE_THIRD_PARTY",
  ServiceTowAwayBoat = "SERVICE_TOW_AWAY_BOAT",
  ServiceTransportation = "SERVICE_TRANSPORTATION",
  ServiceUncrating = "SERVICE_UNCRATING",
  ServiceUnpack = "SERVICE_UNPACK",
}

export const enum Status {
  Delivered = "DELIVERED",
  InStorage = "IN_STORAGE",
  InTransit = "IN_TRANSIT",
  Missing = "MISSING",
  Packed = "PACKED",
  Voided = "VOIDED",
}

export type SurveyAppointmentOutput = {
  __typename: "SurveyAppointmentOutput";
  surveyAvailability?: Maybe<SurveyAvailability>;
  surveyService: SurveyService;
};

export type SurveyAvailability = {
  __typename: "SurveyAvailability";
  allotedSurveyors: Scalars["Int"]["output"];
  bookedSurveyors: Scalars["Int"]["output"];
  createdAt: Scalars["ISO8601DateTime"]["output"];
  crmLinks?: Maybe<Array<CrmLink>>;
  endDatetime: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  startDatetime: Scalars["ISO8601DateTime"]["output"];
  surveyType: Scalars["String"]["output"];
  timezone: Scalars["String"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type SurveyAvailabilityCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for SurveyAvailability. */
export type SurveyAvailabilityConnection = {
  __typename: "SurveyAvailabilityConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SurveyAvailabilityEdge>>>;
  /** A list of nodes. */
  nodes: Array<SurveyAvailability>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type SurveyAvailabilityEdge = {
  __typename: "SurveyAvailabilityEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<SurveyAvailability>;
};

export type SurveyChange = {
  __typename: "SurveyChange";
  estimatedWeight?: Maybe<Scalars["Int"]["output"]>;
  surveyId?: Maybe<Scalars["ID"]["output"]>;
};

export type SurveyChangeRequest = Approvable &
  ChangeRequest & {
    __typename: "SurveyChangeRequest";
    approvalRequest?: Maybe<ApprovalRequest>;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    crmLinks?: Maybe<Array<CrmLink>>;
    id: Scalars["ID"]["output"];
    newSurvey?: Maybe<SurveyChange>;
    newValue: Scalars["JSON"]["output"];
    oldSurvey?: Maybe<SurveyChange>;
    oldValue: Scalars["JSON"]["output"];
    order?: Maybe<Order>;
    status: ApprovalRequestStatus;
    type?: Maybe<ChangeRequestType>;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type SurveyChangeRequestCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type SurveyService = Approvable &
  Service & {
    __typename: "SurveyService";
    accountingStatus: ServiceAccountingStatus;
    actualEndDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    actualGrossWeight?: Maybe<Scalars["Int"]["output"]>;
    actualNetWeight?: Maybe<Scalars["Int"]["output"]>;
    actualStartDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    actualTareWeight?: Maybe<Scalars["Int"]["output"]>;
    actualWeightSource?: Maybe<Scalars["String"]["output"]>;
    approvalStatus?: Maybe<Scalars["String"]["output"]>;
    billable: Scalars["Boolean"]["output"];
    billedWeight?: Maybe<Scalars["Int"]["output"]>;
    billedWeightSource?: Maybe<Scalars["String"]["output"]>;
    completionStatus: ServiceCompletionStatus;
    createdAt: Scalars["ISO8601DateTime"]["output"];
    createdBy?: Maybe<Scalars["ID"]["output"]>;
    crewAssignments: Array<CrewAssignment>;
    crewLocationCapturedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    crewLocationLatitude?: Maybe<Scalars["Float"]["output"]>;
    crewLocationLongitude?: Maybe<Scalars["Float"]["output"]>;
    crmLinks?: Maybe<Array<CrmLink>>;
    description?: Maybe<Scalars["String"]["output"]>;
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    displayName?: Maybe<Scalars["String"]["output"]>;
    endLocation?: Maybe<Location>;
    estimatedWeight?: Maybe<Scalars["Int"]["output"]>;
    id: Scalars["ID"]["output"];
    maxSurveyDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    metadata?: Maybe<Scalars["JSON"]["output"]>;
    milmoveCode?: Maybe<Scalars["String"]["output"]>;
    milmoveId?: Maybe<Scalars["String"]["output"]>;
    minSurveyDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    moveTaskOrder?: Maybe<MoveTaskOrder>;
    name?: Maybe<Scalars["String"]["output"]>;
    notes?: Maybe<Scalars["String"]["output"]>;
    order?: Maybe<Order>;
    orderId?: Maybe<Scalars["ID"]["output"]>;
    parent?: Maybe<Service>;
    parentId?: Maybe<Scalars["Int"]["output"]>;
    plannedEndDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    plannedEndDatetimeZone?: Maybe<Scalars["String"]["output"]>;
    plannedStartDatetime?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
    plannedStartDatetimeZone?: Maybe<Scalars["String"]["output"]>;
    possibleEndDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    possibleStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    preferredStartDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
    reason?: Maybe<Scalars["String"]["output"]>;
    serviceProvider?: Maybe<ServiceProvider>;
    serviceProviderName?: Maybe<Scalars["String"]["output"]>;
    serviceProviderPhoneNumber?: Maybe<Scalars["String"]["output"]>;
    serviceType: ServiceType;
    startLocation?: Maybe<Location>;
    status: ServiceStatus;
    subtype?: Maybe<SurveySubtype>;
    type: ServiceType;
    updatedAt: Scalars["ISO8601DateTime"]["output"];
  };

export type SurveyServiceCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** Survey subtype enum */
export const enum SurveySubtype {
  Onsite = "ONSITE",
  Virtual = "VIRTUAL",
}

/** Autogenerated input type of SwitchSurveyToVirtual */
export type SwitchSurveyToVirtualInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type SwitchSurveyToVirtualOutput = {
  __typename: "SwitchSurveyToVirtualOutput";
  surveyService?: Maybe<SurveyService>;
};

export const enum TagColor {
  Blue = "BLUE",
  Green = "GREEN",
  Multi = "MULTI",
  Orange = "ORANGE",
  Red = "RED",
  White = "WHITE",
  Yellow = "YELLOW",
}

/** Autogenerated input type of UpdateReportConfig */
export type UpdateReportConfigInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  columns?: InputMaybe<Array<Scalars["String"]["input"]>>;
  default?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  owners?: InputMaybe<Array<OwnerInput>>;
  resourceName?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<Scalars["String"]["input"]>>;
  subColumns?: InputMaybe<ReportConfigSubColumnsInput>;
  type?: InputMaybe<ReportConfigType>;
};

export type UpdateReportConfigOutput = {
  __typename: "UpdateReportConfigOutput";
  reportConfig?: Maybe<ReportConfig>;
};

/** Autogenerated input type of UpsertCounselingPreference */
export type UpsertCounselingPreferenceInput = {
  alcoholicBeverages?: InputMaybe<Scalars["Boolean"]["input"]>;
  boat?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  consumables?: InputMaybe<Scalars["Boolean"]["input"]>;
  firearm?: InputMaybe<Scalars["Boolean"]["input"]>;
  gunSafe?: InputMaybe<Scalars["Boolean"]["input"]>;
  mobileHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  motorcycle?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The ID of the Order whose counseling preferences will be upserted */
  orderId: Scalars["ID"]["input"];
  privatelyOwnedVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of UpsertCounselingPreference. */
export type UpsertCounselingPreferencePayload = {
  __typename: "UpsertCounselingPreferencePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  counselingPreference?: Maybe<CounselingPreference>;
};

export type User = {
  __typename: "User";
  crmLinks?: Maybe<Array<CrmLink>>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  userName?: Maybe<Scalars["String"]["output"]>;
};

export type UserCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserProfilePreferences = {
  __typename: "UserProfilePreferences";
  crmLinks?: Maybe<Array<CrmLink>>;
  currencyDecimalSeparator?: Maybe<Scalars["String"]["output"]>;
  currencyGroupingPattern?: Maybe<Scalars["String"]["output"]>;
  currencyGroupingSeparator?: Maybe<Scalars["String"]["output"]>;
  currencyId?: Maybe<Scalars["Int"]["output"]>;
  currencySymbolPlacement?: Maybe<Scalars["String"]["output"]>;
  dateFormat?: Maybe<Scalars["String"]["output"]>;
  hourFormat?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  noOfCurrencyDecimals?: Maybe<Scalars["String"]["output"]>;
  timeZone?: Maybe<Scalars["String"]["output"]>;
  truncateTrailingZeros?: Maybe<Scalars["String"]["output"]>;
};

export type UserProfilePreferencesCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserWidget = {
  __typename: "UserWidget";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  crmLinks?: Maybe<Array<CrmLink>>;
  /** The id of the association. Only used for updating caches */
  id: Scalars["ID"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  /** The user who's widget this is. */
  vtigerUserId: Scalars["Int"]["output"];
  /** The associated widget itself. */
  widget: Widget;
  /** The key of the associated widget. */
  widgetId: Scalars["ID"]["output"];
};

export type UserWidgetCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for UserWidget. */
export type UserWidgetConnection = {
  __typename: "UserWidgetConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserWidgetEdge>>>;
  /** A list of nodes. */
  nodes: Array<UserWidget>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type UserWidgetEdge = {
  __typename: "UserWidgetEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<UserWidget>;
};

export type Vanline = {
  __typename: "Vanline";
  crmLinks?: Maybe<Array<CrmLink>>;
  vanlineManagerId: Scalars["Int"]["output"];
  vanlineName?: Maybe<Scalars["String"]["output"]>;
};

export type VanlineCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for Vanline. */
export type VanlineConnection = {
  __typename: "VanlineConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VanlineEdge>>>;
  /** A list of nodes. */
  nodes: Array<Vanline>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type VanlineEdge = {
  __typename: "VanlineEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Vanline>;
};

export const enum Weight {
  Actual = "ACTUAL",
  Constructive = "CONSTRUCTIVE",
}

export type Widget = {
  __typename: "Widget";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  crmLinks?: Maybe<Array<CrmLink>>;
  /** A machine readable string which is stable across environments and used to refrence the widget in frontend code */
  id: Scalars["ID"]["output"];
  /** The name of the widget. Used for display. Should not be used for indexing/searching */
  name: Scalars["String"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type WidgetCrmLinksArgs = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** The connection type for Widget. */
export type WidgetConnection = {
  __typename: "WidgetConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WidgetEdge>>>;
  /** A list of nodes. */
  nodes: Array<Widget>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type WidgetEdge = {
  __typename: "WidgetEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Widget>;
};

export type UpsertCounselingPreferenceMutationVariables = Exact<{
  input: UpsertCounselingPreferenceInput;
}>;

export type UpsertCounselingPreferenceMutation = {
  __typename: "Mutation";
  upsertCounselingPreference?: {
    __typename: "UpsertCounselingPreferencePayload";
    counselingPreference?: {
      __typename: "CounselingPreference";
      firearm?: boolean | null;
      gunSafe?: boolean | null;
      privatelyOwnedVehicle?: boolean | null;
      boat?: boolean | null;
      consumables?: boolean | null;
      mobileHome?: boolean | null;
      motorcycle?: boolean | null;
      alcoholicBeverages?: boolean | null;
      orderId: string;
    } | null;
  } | null;
};

export type CounselingPreferenceQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type CounselingPreferenceQuery = {
  __typename: "Query";
  counselingPreference?: {
    __typename: "CounselingPreference";
    id: string;
    firearm?: boolean | null;
    gunSafe?: boolean | null;
    privatelyOwnedVehicle?: boolean | null;
    boat?: boolean | null;
    motorcycle?: boolean | null;
    consumables?: boolean | null;
    mobileHome?: boolean | null;
    alcoholicBeverages?: boolean | null;
    orderId: string;
  } | null;
};

export type CreateSurveyAppointmentMutationVariables = Exact<{
  input: CreateSurveyAppointmentInput;
}>;

export type CreateSurveyAppointmentMutation = {
  __typename: "Mutation";
  createSurveyAppointment?: {
    __typename: "SurveyAppointmentOutput";
    surveyAvailability?: {
      __typename: "SurveyAvailability";
      id: string;
    } | null;
    surveyService: { __typename: "SurveyService"; id: string };
  } | null;
};

export type PendingSurveyServiceQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type PendingSurveyServiceQuery = {
  __typename: "Query";
  pendingSurveyService?: {
    __typename: "SurveyService";
    id: string;
    minSurveyDate: any;
    maxSurveyDate: any;
  } | null;
};

export type SurveyAvailabilitiesQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  startDate: Scalars["ISO8601Date"]["input"];
  endDate: Scalars["ISO8601Date"]["input"];
  surveyType: Scalars["String"]["input"];
}>;

export type SurveyAvailabilitiesQuery = {
  __typename: "Query";
  surveyAvailabilities: {
    __typename: "SurveyAvailabilityConnection";
    nodes: Array<{
      __typename: "SurveyAvailability";
      endDatetime: any;
      startDatetime: any;
      timezone: string;
    }>;
  };
};

export type GetOrderByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetOrderByIdQuery = {
  __typename: "Query";
  order?: {
    __typename: "Order";
    shipmentType?: string | null;
    moveTaskOrder?: {
      __typename: "MoveTaskOrder";
      shipments: Array<{
        __typename: "Order";
        id: string;
        counselingStatus: string;
        shipmentType?: string | null;
      }>;
    } | null;
  } | null;
};

export type PpmCostCalculationsQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  weight: Scalars["Int"]["input"];
}>;

export type PpmCostCalculationsQuery = {
  __typename: "Query";
  ppmCostCalculations: {
    __typename: "PpmCostCalculations";
    gcc?: number | null;
    totalIncentive?: number | null;
    totalAdvanceOperatingAllowance?: number | null;
  };
};

export type SwitchSurveyToVirtualMutationVariables = Exact<{
  input: SwitchSurveyToVirtualInput;
}>;

export type SwitchSurveyToVirtualMutation = {
  __typename: "Mutation";
  switchSurveyToVirtual?: {
    __typename: "SwitchSurveyToVirtualOutput";
    surveyService?: {
      __typename: "SurveyService";
      subtype?: SurveySubtype | null;
    } | null;
  } | null;
};

export type CreateAddLocationChangeRequestMutationVariables = Exact<{
  input: CreateAddLocationChangeRequestInput;
}>;

export type CreateAddLocationChangeRequestMutation = {
  __typename: "Mutation";
  createAddLocationChangeRequest?: {
    __typename: "CreateAddLocationChangeRequestPayload";
    changeRequest?:
      | { __typename: "BaseChangeRequest"; id: string }
      | { __typename: "DeliveryDateChangeRequest"; id: string }
      | { __typename: "DestinationAddressChangeRequest"; id: string }
      | { __typename: "ExtraStopDeliveryChangeRequest"; id: string }
      | { __typename: "ExtraStopPickupChangeRequest"; id: string }
      | { __typename: "LoadDateChangeRequest"; id: string }
      | { __typename: "OriginAddressChangeRequest"; id: string }
      | { __typename: "PackDateChangeRequest"; id: string }
      | { __typename: "SITDeliveryAddressChangeRequest"; id: string }
      | { __typename: "SurveyChangeRequest"; id: string }
      | null;
  } | null;
};

export type CreateRemoveLocationChangeRequestMutationVariables = Exact<{
  input: CreateRemoveLocationChangeRequestInput;
}>;

export type CreateRemoveLocationChangeRequestMutation = {
  __typename: "Mutation";
  createRemoveLocationChangeRequest?: {
    __typename: "CreateRemoveLocationChangeRequestPayload";
    changeRequest?:
      | { __typename: "BaseChangeRequest"; id: string }
      | { __typename: "DeliveryDateChangeRequest"; id: string }
      | { __typename: "DestinationAddressChangeRequest"; id: string }
      | { __typename: "ExtraStopDeliveryChangeRequest"; id: string }
      | { __typename: "ExtraStopPickupChangeRequest"; id: string }
      | { __typename: "LoadDateChangeRequest"; id: string }
      | { __typename: "OriginAddressChangeRequest"; id: string }
      | { __typename: "PackDateChangeRequest"; id: string }
      | { __typename: "SITDeliveryAddressChangeRequest"; id: string }
      | { __typename: "SurveyChangeRequest"; id: string }
      | null;
  } | null;
};

export type CreateUpdateLocationChangeRequestMutationVariables = Exact<{
  input: CreateUpdateLocationChangeRequestInput;
}>;

export type CreateUpdateLocationChangeRequestMutation = {
  __typename: "Mutation";
  createUpdateLocationChangeRequest?: {
    __typename: "CreateUpdateLocationChangeRequestPayload";
    changeRequest?:
      | { __typename: "BaseChangeRequest"; id: string }
      | { __typename: "DeliveryDateChangeRequest"; id: string }
      | { __typename: "DestinationAddressChangeRequest"; id: string }
      | { __typename: "ExtraStopDeliveryChangeRequest"; id: string }
      | { __typename: "ExtraStopPickupChangeRequest"; id: string }
      | { __typename: "LoadDateChangeRequest"; id: string }
      | { __typename: "OriginAddressChangeRequest"; id: string }
      | { __typename: "PackDateChangeRequest"; id: string }
      | { __typename: "SITDeliveryAddressChangeRequest"; id: string }
      | { __typename: "SurveyChangeRequest"; id: string }
      | null;
  } | null;
};

export type CancelChangeRequestMutationVariables = Exact<{
  input: CancelChangeRequestInput;
}>;

export type CancelChangeRequestMutation = {
  __typename: "Mutation";
  cancelChangeRequest?: {
    __typename: "CancelChangeRequestPayload";
    changeRequest?:
      | { __typename: "BaseChangeRequest"; id: string }
      | { __typename: "DeliveryDateChangeRequest"; id: string }
      | { __typename: "DestinationAddressChangeRequest"; id: string }
      | { __typename: "ExtraStopDeliveryChangeRequest"; id: string }
      | { __typename: "ExtraStopPickupChangeRequest"; id: string }
      | { __typename: "LoadDateChangeRequest"; id: string }
      | { __typename: "OriginAddressChangeRequest"; id: string }
      | { __typename: "PackDateChangeRequest"; id: string }
      | { __typename: "SITDeliveryAddressChangeRequest"; id: string }
      | { __typename: "SurveyChangeRequest"; id: string }
      | null;
  } | null;
};

export type DismissChangeRequestMutationVariables = Exact<{
  input: DismissChangeRequestInput;
}>;

export type DismissChangeRequestMutation = {
  __typename: "Mutation";
  dismissChangeRequest?: {
    __typename: "DismissChangeRequestPayload";
    changeRequest?:
      | { __typename: "BaseChangeRequest"; id: string }
      | { __typename: "DeliveryDateChangeRequest"; id: string }
      | { __typename: "DestinationAddressChangeRequest"; id: string }
      | { __typename: "ExtraStopDeliveryChangeRequest"; id: string }
      | { __typename: "ExtraStopPickupChangeRequest"; id: string }
      | { __typename: "LoadDateChangeRequest"; id: string }
      | { __typename: "OriginAddressChangeRequest"; id: string }
      | { __typename: "PackDateChangeRequest"; id: string }
      | { __typename: "SITDeliveryAddressChangeRequest"; id: string }
      | { __typename: "SurveyChangeRequest"; id: string }
      | null;
  } | null;
};

export type MoveTrackerStepsQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type MoveTrackerStepsQuery = {
  __typename: "Query";
  moveTrackerSteps: {
    __typename: "MoveTrackerStepConnection";
    nodes: Array<{
      __typename: "MoveTrackerStep";
      key: MoveTrackerKey;
      stepStatus: MoveTrackerStepStatus;
      service?: {
        __typename: "BaseService";
        id: string;
        status: ServiceStatus;
        type: ServiceType;
        plannedStartDatetime?: any | null;
        plannedStartDatetimeZone?: string | null;
        plannedEndDatetime?: any | null;
        plannedEndDatetimeZone?: string | null;
        actualEndDatetime?: any | null;
        preferredStartDate?: any | null;
        possibleStartDate?: any | null;
        possibleEndDate?: any | null;
        serviceProviderName?: string | null;
        serviceProviderPhoneNumber?: string | null;
        crewAssignments: Array<{
          __typename: "CrewAssignment";
          lead?: boolean | null;
          asset: {
            __typename: "Employee";
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            displayName: string;
            attachments?: Array<{
              __typename: "EmployeeAttachment";
              url: string;
            }> | null;
          };
        }>;
      } | null;
    }>;
  };
};

export type GetDateCardDatesQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type GetDateCardDatesQuery = {
  __typename: "Query";
  order?: {
    __typename: "Order";
    services: Array<
      | {
          __typename: "BaseService";
          serviceType: ServiceType;
          plannedStartDatetime?: any | null;
          plannedStartDatetimeZone?: string | null;
          plannedEndDatetime?: any | null;
          plannedEndDatetimeZone?: string | null;
          actualEndDatetime?: any | null;
          preferredStartDate?: any | null;
          possibleStartDate?: any | null;
          possibleEndDate?: any | null;
        }
      | {
          __typename: "ClaimService";
          serviceType: ServiceType;
          plannedStartDatetime?: any | null;
          plannedStartDatetimeZone?: string | null;
          plannedEndDatetime?: any | null;
          plannedEndDatetimeZone?: string | null;
          actualEndDatetime?: any | null;
          preferredStartDate?: any | null;
          possibleStartDate?: any | null;
          possibleEndDate?: any | null;
        }
      | {
          __typename: "SurveyService";
          serviceType: ServiceType;
          plannedStartDatetime?: any | null;
          plannedStartDatetimeZone?: string | null;
          plannedEndDatetime?: any | null;
          plannedEndDatetimeZone?: string | null;
          actualEndDatetime?: any | null;
          preferredStartDate?: any | null;
          possibleStartDate?: any | null;
          possibleEndDate?: any | null;
        }
    >;
  } | null;
};

export type GetMoveTaskOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetMoveTaskOrdersQuery = {
  __typename: "Query";
  moveTaskOrders: {
    __typename: "MoveTaskOrderConnection";
    nodes: Array<{
      __typename: "MoveTaskOrder";
      id: string;
      shipments: Array<{
        __typename: "Order";
        id: string;
        receivedDate?: any | null;
        shipmentType?: string | null;
        number: string;
        status: OrderStatus;
        origin: {
          __typename: "OrderAddress";
          locality?: string | null;
          region?: string | null;
        };
        destination: {
          __typename: "OrderAddress";
          locality?: string | null;
          region?: string | null;
        };
      }>;
    }>;
  };
};

export type CurrentShipmentQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentShipmentQuery = {
  __typename: "Query";
  currentShipment?: { __typename: "Order"; id: string } | null;
};

export const UpsertCounselingPreferenceDocument = gql`
  mutation UpsertCounselingPreference(
    $input: UpsertCounselingPreferenceInput!
  ) {
    upsertCounselingPreference(input: $input) {
      counselingPreference {
        firearm
        gunSafe
        privatelyOwnedVehicle
        boat
        consumables
        mobileHome
        motorcycle
        alcoholicBeverages
        orderId
      }
    }
  }
`;
export type UpsertCounselingPreferenceMutationFn = Apollo.MutationFunction<
  UpsertCounselingPreferenceMutation,
  UpsertCounselingPreferenceMutationVariables
>;

/**
 * __useUpsertCounselingPreferenceMutation__
 *
 * To run a mutation, you first call `useUpsertCounselingPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCounselingPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCounselingPreferenceMutation, { data, loading, error }] = useUpsertCounselingPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCounselingPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertCounselingPreferenceMutation,
    UpsertCounselingPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertCounselingPreferenceMutation,
    UpsertCounselingPreferenceMutationVariables
  >(UpsertCounselingPreferenceDocument, options);
}
export type UpsertCounselingPreferenceMutationHookResult = ReturnType<
  typeof useUpsertCounselingPreferenceMutation
>;
export type UpsertCounselingPreferenceMutationResult =
  Apollo.MutationResult<UpsertCounselingPreferenceMutation>;
export type UpsertCounselingPreferenceMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertCounselingPreferenceMutation,
    UpsertCounselingPreferenceMutationVariables
  >;
export const CounselingPreferenceDocument = gql`
  query CounselingPreference($orderId: ID!) {
    counselingPreference(orderId: $orderId) {
      id
      firearm
      gunSafe
      privatelyOwnedVehicle
      boat
      motorcycle
      consumables
      mobileHome
      alcoholicBeverages
      orderId
    }
  }
`;

/**
 * __useCounselingPreferenceQuery__
 *
 * To run a query within a React component, call `useCounselingPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselingPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselingPreferenceQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCounselingPreferenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    CounselingPreferenceQuery,
    CounselingPreferenceQueryVariables
  > &
    (
      | { variables: CounselingPreferenceQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CounselingPreferenceQuery,
    CounselingPreferenceQueryVariables
  >(CounselingPreferenceDocument, options);
}
export function useCounselingPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CounselingPreferenceQuery,
    CounselingPreferenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CounselingPreferenceQuery,
    CounselingPreferenceQueryVariables
  >(CounselingPreferenceDocument, options);
}
export function useCounselingPreferenceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CounselingPreferenceQuery,
        CounselingPreferenceQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CounselingPreferenceQuery,
    CounselingPreferenceQueryVariables
  >(CounselingPreferenceDocument, options);
}
export type CounselingPreferenceQueryHookResult = ReturnType<
  typeof useCounselingPreferenceQuery
>;
export type CounselingPreferenceLazyQueryHookResult = ReturnType<
  typeof useCounselingPreferenceLazyQuery
>;
export type CounselingPreferenceSuspenseQueryHookResult = ReturnType<
  typeof useCounselingPreferenceSuspenseQuery
>;
export type CounselingPreferenceQueryResult = Apollo.QueryResult<
  CounselingPreferenceQuery,
  CounselingPreferenceQueryVariables
>;
export const CreateSurveyAppointmentDocument = gql`
  mutation CreateSurveyAppointment($input: CreateSurveyAppointmentInput!) {
    createSurveyAppointment(input: $input) {
      surveyAvailability {
        id
      }
      surveyService {
        id
      }
    }
  }
`;
export type CreateSurveyAppointmentMutationFn = Apollo.MutationFunction<
  CreateSurveyAppointmentMutation,
  CreateSurveyAppointmentMutationVariables
>;

/**
 * __useCreateSurveyAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateSurveyAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyAppointmentMutation, { data, loading, error }] = useCreateSurveyAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSurveyAppointmentMutation,
    CreateSurveyAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSurveyAppointmentMutation,
    CreateSurveyAppointmentMutationVariables
  >(CreateSurveyAppointmentDocument, options);
}
export type CreateSurveyAppointmentMutationHookResult = ReturnType<
  typeof useCreateSurveyAppointmentMutation
>;
export type CreateSurveyAppointmentMutationResult =
  Apollo.MutationResult<CreateSurveyAppointmentMutation>;
export type CreateSurveyAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateSurveyAppointmentMutation,
  CreateSurveyAppointmentMutationVariables
>;
export const PendingSurveyServiceDocument = gql`
  query PendingSurveyService($orderId: ID!) {
    pendingSurveyService(orderId: $orderId) {
      id
      minSurveyDate
      maxSurveyDate
    }
  }
`;

/**
 * __usePendingSurveyServiceQuery__
 *
 * To run a query within a React component, call `usePendingSurveyServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingSurveyServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingSurveyServiceQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function usePendingSurveyServiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    PendingSurveyServiceQuery,
    PendingSurveyServiceQueryVariables
  > &
    (
      | { variables: PendingSurveyServiceQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PendingSurveyServiceQuery,
    PendingSurveyServiceQueryVariables
  >(PendingSurveyServiceDocument, options);
}
export function usePendingSurveyServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingSurveyServiceQuery,
    PendingSurveyServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PendingSurveyServiceQuery,
    PendingSurveyServiceQueryVariables
  >(PendingSurveyServiceDocument, options);
}
export function usePendingSurveyServiceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PendingSurveyServiceQuery,
        PendingSurveyServiceQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PendingSurveyServiceQuery,
    PendingSurveyServiceQueryVariables
  >(PendingSurveyServiceDocument, options);
}
export type PendingSurveyServiceQueryHookResult = ReturnType<
  typeof usePendingSurveyServiceQuery
>;
export type PendingSurveyServiceLazyQueryHookResult = ReturnType<
  typeof usePendingSurveyServiceLazyQuery
>;
export type PendingSurveyServiceSuspenseQueryHookResult = ReturnType<
  typeof usePendingSurveyServiceSuspenseQuery
>;
export type PendingSurveyServiceQueryResult = Apollo.QueryResult<
  PendingSurveyServiceQuery,
  PendingSurveyServiceQueryVariables
>;
export const SurveyAvailabilitiesDocument = gql`
  query SurveyAvailabilities(
    $orderId: ID!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $surveyType: String!
  ) {
    surveyAvailabilities(
      orderId: $orderId
      startDate: $startDate
      endDate: $endDate
      surveyType: $surveyType
    ) {
      nodes {
        endDatetime
        startDatetime
        timezone
      }
    }
  }
`;

/**
 * __useSurveyAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useSurveyAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyAvailabilitiesQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      surveyType: // value for 'surveyType'
 *   },
 * });
 */
export function useSurveyAvailabilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SurveyAvailabilitiesQuery,
    SurveyAvailabilitiesQueryVariables
  > &
    (
      | { variables: SurveyAvailabilitiesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SurveyAvailabilitiesQuery,
    SurveyAvailabilitiesQueryVariables
  >(SurveyAvailabilitiesDocument, options);
}
export function useSurveyAvailabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyAvailabilitiesQuery,
    SurveyAvailabilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SurveyAvailabilitiesQuery,
    SurveyAvailabilitiesQueryVariables
  >(SurveyAvailabilitiesDocument, options);
}
export function useSurveyAvailabilitiesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SurveyAvailabilitiesQuery,
        SurveyAvailabilitiesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SurveyAvailabilitiesQuery,
    SurveyAvailabilitiesQueryVariables
  >(SurveyAvailabilitiesDocument, options);
}
export type SurveyAvailabilitiesQueryHookResult = ReturnType<
  typeof useSurveyAvailabilitiesQuery
>;
export type SurveyAvailabilitiesLazyQueryHookResult = ReturnType<
  typeof useSurveyAvailabilitiesLazyQuery
>;
export type SurveyAvailabilitiesSuspenseQueryHookResult = ReturnType<
  typeof useSurveyAvailabilitiesSuspenseQuery
>;
export type SurveyAvailabilitiesQueryResult = Apollo.QueryResult<
  SurveyAvailabilitiesQuery,
  SurveyAvailabilitiesQueryVariables
>;
export const GetOrderByIdDocument = gql`
  query GetOrderById($id: ID!) {
    order(id: $id) {
      shipmentType
      moveTaskOrder {
        shipments {
          id
          counselingStatus
          shipmentType
        }
      }
    }
  }
`;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables
  > &
    (
      | { variables: GetOrderByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export function useGetOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export function useGetOrderByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetOrderByIdQuery,
        GetOrderByIdQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export type GetOrderByIdQueryHookResult = ReturnType<
  typeof useGetOrderByIdQuery
>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrderByIdLazyQuery
>;
export type GetOrderByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderByIdSuspenseQuery
>;
export type GetOrderByIdQueryResult = Apollo.QueryResult<
  GetOrderByIdQuery,
  GetOrderByIdQueryVariables
>;
export const PpmCostCalculationsDocument = gql`
  query PPMCostCalculations($orderId: ID!, $weight: Int!) {
    ppmCostCalculations(orderId: $orderId, weight: $weight) {
      gcc
      totalIncentive
      totalAdvanceOperatingAllowance
    }
  }
`;

/**
 * __usePpmCostCalculationsQuery__
 *
 * To run a query within a React component, call `usePpmCostCalculationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePpmCostCalculationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePpmCostCalculationsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function usePpmCostCalculationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PpmCostCalculationsQuery,
    PpmCostCalculationsQueryVariables
  > &
    (
      | { variables: PpmCostCalculationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PpmCostCalculationsQuery,
    PpmCostCalculationsQueryVariables
  >(PpmCostCalculationsDocument, options);
}
export function usePpmCostCalculationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PpmCostCalculationsQuery,
    PpmCostCalculationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PpmCostCalculationsQuery,
    PpmCostCalculationsQueryVariables
  >(PpmCostCalculationsDocument, options);
}
export function usePpmCostCalculationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PpmCostCalculationsQuery,
        PpmCostCalculationsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PpmCostCalculationsQuery,
    PpmCostCalculationsQueryVariables
  >(PpmCostCalculationsDocument, options);
}
export type PpmCostCalculationsQueryHookResult = ReturnType<
  typeof usePpmCostCalculationsQuery
>;
export type PpmCostCalculationsLazyQueryHookResult = ReturnType<
  typeof usePpmCostCalculationsLazyQuery
>;
export type PpmCostCalculationsSuspenseQueryHookResult = ReturnType<
  typeof usePpmCostCalculationsSuspenseQuery
>;
export type PpmCostCalculationsQueryResult = Apollo.QueryResult<
  PpmCostCalculationsQuery,
  PpmCostCalculationsQueryVariables
>;
export const SwitchSurveyToVirtualDocument = gql`
  mutation SwitchSurveyToVirtual($input: SwitchSurveyToVirtualInput!) {
    switchSurveyToVirtual(input: $input) {
      surveyService {
        subtype
      }
    }
  }
`;
export type SwitchSurveyToVirtualMutationFn = Apollo.MutationFunction<
  SwitchSurveyToVirtualMutation,
  SwitchSurveyToVirtualMutationVariables
>;

/**
 * __useSwitchSurveyToVirtualMutation__
 *
 * To run a mutation, you first call `useSwitchSurveyToVirtualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchSurveyToVirtualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchSurveyToVirtualMutation, { data, loading, error }] = useSwitchSurveyToVirtualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchSurveyToVirtualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchSurveyToVirtualMutation,
    SwitchSurveyToVirtualMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SwitchSurveyToVirtualMutation,
    SwitchSurveyToVirtualMutationVariables
  >(SwitchSurveyToVirtualDocument, options);
}
export type SwitchSurveyToVirtualMutationHookResult = ReturnType<
  typeof useSwitchSurveyToVirtualMutation
>;
export type SwitchSurveyToVirtualMutationResult =
  Apollo.MutationResult<SwitchSurveyToVirtualMutation>;
export type SwitchSurveyToVirtualMutationOptions = Apollo.BaseMutationOptions<
  SwitchSurveyToVirtualMutation,
  SwitchSurveyToVirtualMutationVariables
>;
export const CreateAddLocationChangeRequestDocument = gql`
  mutation CreateAddLocationChangeRequest(
    $input: CreateAddLocationChangeRequestInput!
  ) {
    createAddLocationChangeRequest(input: $input) {
      changeRequest {
        id
      }
    }
  }
`;
export type CreateAddLocationChangeRequestMutationFn = Apollo.MutationFunction<
  CreateAddLocationChangeRequestMutation,
  CreateAddLocationChangeRequestMutationVariables
>;

/**
 * __useCreateAddLocationChangeRequestMutation__
 *
 * To run a mutation, you first call `useCreateAddLocationChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddLocationChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddLocationChangeRequestMutation, { data, loading, error }] = useCreateAddLocationChangeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAddLocationChangeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAddLocationChangeRequestMutation,
    CreateAddLocationChangeRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAddLocationChangeRequestMutation,
    CreateAddLocationChangeRequestMutationVariables
  >(CreateAddLocationChangeRequestDocument, options);
}
export type CreateAddLocationChangeRequestMutationHookResult = ReturnType<
  typeof useCreateAddLocationChangeRequestMutation
>;
export type CreateAddLocationChangeRequestMutationResult =
  Apollo.MutationResult<CreateAddLocationChangeRequestMutation>;
export type CreateAddLocationChangeRequestMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAddLocationChangeRequestMutation,
    CreateAddLocationChangeRequestMutationVariables
  >;
export const CreateRemoveLocationChangeRequestDocument = gql`
  mutation CreateRemoveLocationChangeRequest(
    $input: CreateRemoveLocationChangeRequestInput!
  ) {
    createRemoveLocationChangeRequest(input: $input) {
      changeRequest {
        id
      }
    }
  }
`;
export type CreateRemoveLocationChangeRequestMutationFn =
  Apollo.MutationFunction<
    CreateRemoveLocationChangeRequestMutation,
    CreateRemoveLocationChangeRequestMutationVariables
  >;

/**
 * __useCreateRemoveLocationChangeRequestMutation__
 *
 * To run a mutation, you first call `useCreateRemoveLocationChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRemoveLocationChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRemoveLocationChangeRequestMutation, { data, loading, error }] = useCreateRemoveLocationChangeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRemoveLocationChangeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRemoveLocationChangeRequestMutation,
    CreateRemoveLocationChangeRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRemoveLocationChangeRequestMutation,
    CreateRemoveLocationChangeRequestMutationVariables
  >(CreateRemoveLocationChangeRequestDocument, options);
}
export type CreateRemoveLocationChangeRequestMutationHookResult = ReturnType<
  typeof useCreateRemoveLocationChangeRequestMutation
>;
export type CreateRemoveLocationChangeRequestMutationResult =
  Apollo.MutationResult<CreateRemoveLocationChangeRequestMutation>;
export type CreateRemoveLocationChangeRequestMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRemoveLocationChangeRequestMutation,
    CreateRemoveLocationChangeRequestMutationVariables
  >;
export const CreateUpdateLocationChangeRequestDocument = gql`
  mutation CreateUpdateLocationChangeRequest(
    $input: CreateUpdateLocationChangeRequestInput!
  ) {
    createUpdateLocationChangeRequest(input: $input) {
      changeRequest {
        id
      }
    }
  }
`;
export type CreateUpdateLocationChangeRequestMutationFn =
  Apollo.MutationFunction<
    CreateUpdateLocationChangeRequestMutation,
    CreateUpdateLocationChangeRequestMutationVariables
  >;

/**
 * __useCreateUpdateLocationChangeRequestMutation__
 *
 * To run a mutation, you first call `useCreateUpdateLocationChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateLocationChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateLocationChangeRequestMutation, { data, loading, error }] = useCreateUpdateLocationChangeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateLocationChangeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUpdateLocationChangeRequestMutation,
    CreateUpdateLocationChangeRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUpdateLocationChangeRequestMutation,
    CreateUpdateLocationChangeRequestMutationVariables
  >(CreateUpdateLocationChangeRequestDocument, options);
}
export type CreateUpdateLocationChangeRequestMutationHookResult = ReturnType<
  typeof useCreateUpdateLocationChangeRequestMutation
>;
export type CreateUpdateLocationChangeRequestMutationResult =
  Apollo.MutationResult<CreateUpdateLocationChangeRequestMutation>;
export type CreateUpdateLocationChangeRequestMutationOptions =
  Apollo.BaseMutationOptions<
    CreateUpdateLocationChangeRequestMutation,
    CreateUpdateLocationChangeRequestMutationVariables
  >;
export const CancelChangeRequestDocument = gql`
  mutation CancelChangeRequest($input: CancelChangeRequestInput!) {
    cancelChangeRequest(input: $input) {
      changeRequest {
        id
      }
    }
  }
`;
export type CancelChangeRequestMutationFn = Apollo.MutationFunction<
  CancelChangeRequestMutation,
  CancelChangeRequestMutationVariables
>;

/**
 * __useCancelChangeRequestMutation__
 *
 * To run a mutation, you first call `useCancelChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelChangeRequestMutation, { data, loading, error }] = useCancelChangeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelChangeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelChangeRequestMutation,
    CancelChangeRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelChangeRequestMutation,
    CancelChangeRequestMutationVariables
  >(CancelChangeRequestDocument, options);
}
export type CancelChangeRequestMutationHookResult = ReturnType<
  typeof useCancelChangeRequestMutation
>;
export type CancelChangeRequestMutationResult =
  Apollo.MutationResult<CancelChangeRequestMutation>;
export type CancelChangeRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelChangeRequestMutation,
  CancelChangeRequestMutationVariables
>;
export const DismissChangeRequestDocument = gql`
  mutation DismissChangeRequest($input: DismissChangeRequestInput!) {
    dismissChangeRequest(input: $input) {
      changeRequest {
        id
      }
    }
  }
`;
export type DismissChangeRequestMutationFn = Apollo.MutationFunction<
  DismissChangeRequestMutation,
  DismissChangeRequestMutationVariables
>;

/**
 * __useDismissChangeRequestMutation__
 *
 * To run a mutation, you first call `useDismissChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissChangeRequestMutation, { data, loading, error }] = useDismissChangeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissChangeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissChangeRequestMutation,
    DismissChangeRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissChangeRequestMutation,
    DismissChangeRequestMutationVariables
  >(DismissChangeRequestDocument, options);
}
export type DismissChangeRequestMutationHookResult = ReturnType<
  typeof useDismissChangeRequestMutation
>;
export type DismissChangeRequestMutationResult =
  Apollo.MutationResult<DismissChangeRequestMutation>;
export type DismissChangeRequestMutationOptions = Apollo.BaseMutationOptions<
  DismissChangeRequestMutation,
  DismissChangeRequestMutationVariables
>;
export const MoveTrackerStepsDocument = gql`
  query MoveTrackerSteps($orderId: ID!) {
    moveTrackerSteps(orderId: $orderId) {
      nodes {
        __typename
        key
        service {
          __typename
          id
          status
          type
          plannedStartDatetime
          plannedStartDatetimeZone
          plannedEndDatetime
          plannedEndDatetimeZone
          actualEndDatetime
          preferredStartDate
          possibleStartDate
          possibleEndDate
          serviceProviderName
          serviceProviderPhoneNumber
          crewAssignments {
            lead
            asset {
              id
              firstName
              lastName
              displayName
              attachments {
                url
              }
            }
          }
        }
        stepStatus
      }
    }
  }
`;

/**
 * __useMoveTrackerStepsQuery__
 *
 * To run a query within a React component, call `useMoveTrackerStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveTrackerStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveTrackerStepsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useMoveTrackerStepsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MoveTrackerStepsQuery,
    MoveTrackerStepsQueryVariables
  > &
    (
      | { variables: MoveTrackerStepsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MoveTrackerStepsQuery, MoveTrackerStepsQueryVariables>(
    MoveTrackerStepsDocument,
    options
  );
}
export function useMoveTrackerStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MoveTrackerStepsQuery,
    MoveTrackerStepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MoveTrackerStepsQuery,
    MoveTrackerStepsQueryVariables
  >(MoveTrackerStepsDocument, options);
}
export function useMoveTrackerStepsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MoveTrackerStepsQuery,
        MoveTrackerStepsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MoveTrackerStepsQuery,
    MoveTrackerStepsQueryVariables
  >(MoveTrackerStepsDocument, options);
}
export type MoveTrackerStepsQueryHookResult = ReturnType<
  typeof useMoveTrackerStepsQuery
>;
export type MoveTrackerStepsLazyQueryHookResult = ReturnType<
  typeof useMoveTrackerStepsLazyQuery
>;
export type MoveTrackerStepsSuspenseQueryHookResult = ReturnType<
  typeof useMoveTrackerStepsSuspenseQuery
>;
export type MoveTrackerStepsQueryResult = Apollo.QueryResult<
  MoveTrackerStepsQuery,
  MoveTrackerStepsQueryVariables
>;
export const GetDateCardDatesDocument = gql`
  query GetDateCardDates($orderId: ID!) {
    order(id: $orderId) {
      services {
        serviceType
        plannedStartDatetime
        plannedStartDatetimeZone
        plannedEndDatetime
        plannedEndDatetimeZone
        actualEndDatetime
        preferredStartDate
        possibleStartDate
        possibleEndDate
      }
    }
  }
`;

/**
 * __useGetDateCardDatesQuery__
 *
 * To run a query within a React component, call `useGetDateCardDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDateCardDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDateCardDatesQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetDateCardDatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDateCardDatesQuery,
    GetDateCardDatesQueryVariables
  > &
    (
      | { variables: GetDateCardDatesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDateCardDatesQuery, GetDateCardDatesQueryVariables>(
    GetDateCardDatesDocument,
    options
  );
}
export function useGetDateCardDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDateCardDatesQuery,
    GetDateCardDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDateCardDatesQuery,
    GetDateCardDatesQueryVariables
  >(GetDateCardDatesDocument, options);
}
export function useGetDateCardDatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetDateCardDatesQuery,
        GetDateCardDatesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDateCardDatesQuery,
    GetDateCardDatesQueryVariables
  >(GetDateCardDatesDocument, options);
}
export type GetDateCardDatesQueryHookResult = ReturnType<
  typeof useGetDateCardDatesQuery
>;
export type GetDateCardDatesLazyQueryHookResult = ReturnType<
  typeof useGetDateCardDatesLazyQuery
>;
export type GetDateCardDatesSuspenseQueryHookResult = ReturnType<
  typeof useGetDateCardDatesSuspenseQuery
>;
export type GetDateCardDatesQueryResult = Apollo.QueryResult<
  GetDateCardDatesQuery,
  GetDateCardDatesQueryVariables
>;
export const GetMoveTaskOrdersDocument = gql`
  query GetMoveTaskOrders {
    moveTaskOrders {
      nodes {
        id
        shipments {
          id
          origin {
            locality
            region
          }
          destination {
            locality
            region
          }
          receivedDate
          shipmentType
          number
          status
        }
      }
    }
  }
`;

/**
 * __useGetMoveTaskOrdersQuery__
 *
 * To run a query within a React component, call `useGetMoveTaskOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoveTaskOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoveTaskOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMoveTaskOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMoveTaskOrdersQuery,
    GetMoveTaskOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMoveTaskOrdersQuery,
    GetMoveTaskOrdersQueryVariables
  >(GetMoveTaskOrdersDocument, options);
}
export function useGetMoveTaskOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMoveTaskOrdersQuery,
    GetMoveTaskOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMoveTaskOrdersQuery,
    GetMoveTaskOrdersQueryVariables
  >(GetMoveTaskOrdersDocument, options);
}
export function useGetMoveTaskOrdersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetMoveTaskOrdersQuery,
        GetMoveTaskOrdersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMoveTaskOrdersQuery,
    GetMoveTaskOrdersQueryVariables
  >(GetMoveTaskOrdersDocument, options);
}
export type GetMoveTaskOrdersQueryHookResult = ReturnType<
  typeof useGetMoveTaskOrdersQuery
>;
export type GetMoveTaskOrdersLazyQueryHookResult = ReturnType<
  typeof useGetMoveTaskOrdersLazyQuery
>;
export type GetMoveTaskOrdersSuspenseQueryHookResult = ReturnType<
  typeof useGetMoveTaskOrdersSuspenseQuery
>;
export type GetMoveTaskOrdersQueryResult = Apollo.QueryResult<
  GetMoveTaskOrdersQuery,
  GetMoveTaskOrdersQueryVariables
>;
export const CurrentShipmentDocument = gql`
  query CurrentShipment {
    currentShipment {
      id
    }
  }
`;

/**
 * __useCurrentShipmentQuery__
 *
 * To run a query within a React component, call `useCurrentShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentShipmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentShipmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentShipmentQuery,
    CurrentShipmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentShipmentQuery, CurrentShipmentQueryVariables>(
    CurrentShipmentDocument,
    options
  );
}
export function useCurrentShipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentShipmentQuery,
    CurrentShipmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentShipmentQuery,
    CurrentShipmentQueryVariables
  >(CurrentShipmentDocument, options);
}
export function useCurrentShipmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CurrentShipmentQuery,
        CurrentShipmentQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CurrentShipmentQuery,
    CurrentShipmentQueryVariables
  >(CurrentShipmentDocument, options);
}
export type CurrentShipmentQueryHookResult = ReturnType<
  typeof useCurrentShipmentQuery
>;
export type CurrentShipmentLazyQueryHookResult = ReturnType<
  typeof useCurrentShipmentLazyQuery
>;
export type CurrentShipmentSuspenseQueryHookResult = ReturnType<
  typeof useCurrentShipmentSuspenseQuery
>;
export type CurrentShipmentQueryResult = Apollo.QueryResult<
  CurrentShipmentQuery,
  CurrentShipmentQueryVariables
>;

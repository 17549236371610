import { Order } from "models";
import { useSearchParams } from "next/navigation";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useNotify } from "services/notification/NotificationContext";
import { datadogRum } from "@datadog/browser-rum";
import { useAuth } from "services/auth/AuthContext";
import { useCurrentShipmentLazyQuery } from "__generated__/types";

//------------
// CONSTANTS
//------------

const includedFields = [
  "origin",
  "destination",
  "crew_tracker",
  "change_requests",
  "change_requests.changeable",
  "inventory_items",
  "inventory_items.item_activities",
  "inventory_items.inventory_room",
  "inventory_items.loss_or_damage_notice",
  "inventory_tracker",
  "inventory_items.claim_details",
  "claims",
  "claims.current_offer",
  "claims.current_offer.claim_detail_offer_activities",
  "claims.claim_details",
  "claims.claim_details.claim",
  "claims.claim_details.current_offer",
  "claims.claim_details.current_offer.claim_detail_offer_activities",
  "services",
  "surveys",
  "surveys.segments.order",
  "surveys.segments.items",
  "surveys.items",
  "surveys.items.room",
  "surveys.items.segment",
  "surveys.order",
  "surveys.segments",
  "surveys.service",
  "customer",
  "entitlement",
  "move_type",
  "locations",
  "assigned_user",
];

const extraAttributes = ["entitlement_weight", "personal_property_weight"];

//------------
// TYPES
//------------

type Status =
  | "unloadedNoSaved"
  | "loadingNoSaved"
  | "unloadedSaved"
  | "loadingSaved"
  | "orderless"
  | "error"
  | "loaded"
  | "refreshing";

interface Base {
  status: Status;
  loading: boolean;
  order: Order | null;
  savedOrderId: string | null;
  error: string | Error | null;
}

/**
 * Initial state with no saved order id
 */
interface OrderLoaderStateUnloadedNoSaved extends Base {
  status: "unloadedNoSaved";
  loading: false;
  order: null;
  savedOrderId: null;
  error: null;
}

/**
 * Waiting for response back from Orders.first() request
 */
interface OrderLoaderStateLoadingNoSaved extends Base {
  status: "loadingNoSaved";
  loading: true;
  order: null;
  savedOrderId: null;
  error: null;
}

/**
 * Initial state with saved order id
 */
interface OrderLoaderStateUnloadedSaved extends Base {
  status: "unloadedSaved";
  loading: false;
  order: null;
  savedOrderId: string;
  error: null;
}

/**
 * Waiting for response back for order/[id] request
 */
interface OrderLoaderStateLoadingSaved extends Base {
  status: "loadingSaved";
  loading: true;
  order: null;
  savedOrderId: string;
  error: null;
}

/**
 * Order came back successfully and is set
 */
interface OrderLoaderStateLoaded extends Base {
  status: "loaded";
  loading: false;
  order: Order;
  savedOrderId: string;
  error: null;
}

/**
 * Empty list of orders was retrieved for the customer
 */
interface OrderLoaderStateOrderless extends Base {
  status: "orderless";
  loading: false;
  order: null;
  savedOrderId: null;
  error: null;
}

/**
 * Unhandled error on request
 */
interface OrderLoaderStateError extends Base {
  status: "error";
  loading: false;
  order: null;
  savedOrderId: null;
  error: string | Error;
}

/**
 * Order is already loaded but we are refreshing
 */
interface OrderLoaderStateRefreshing extends Base {
  status: "refreshing";
  loading: true;
  order: Order;
  savedOrderId: string;
  error: null;
}

export type OrderLoaderState =
  | OrderLoaderStateUnloadedNoSaved
  | OrderLoaderStateLoadingNoSaved
  | OrderLoaderStateUnloadedSaved
  | OrderLoaderStateLoadingSaved
  | OrderLoaderStateLoaded
  | OrderLoaderStateOrderless
  | OrderLoaderStateError
  | OrderLoaderStateRefreshing;

export type OrderLoaderContext = OrderLoaderState & {
  /**
   * Clears the current order which will
   * trigger a fresh reload of the first order.
   * Pass an optional order id to force loading
   * a particular order.
   */
  reset(orderId?: string): void;
  /**
   * Reloads the current order
   */
  refresh(): void;
  /**
   * Triggers loading of a new order
   */
  changeOrder(orderId: string): void;
};

//----------------
// OrderLoaderContext
//----------------

const OrderLoaderContext = createContext<OrderLoaderContext>({
  status: "unloadedNoSaved",
  loading: false,
  order: null,
  savedOrderId: null,
  error: null,
  reset: () => null,
  refresh: () => null,
  changeOrder: () => null,
});

//------------------------
// OrderLoader reducer
//------------------------

type Action =
  | {
      type: "START_LOAD";
      orderId: string | null;
    }
  | { type: "LOAD_SUCCESS"; order: Order }
  | { type: "LOAD_NOT_FOUND" }
  | { type: "LOAD_ERROR"; error: Error }
  | { type: "RESET"; orderId?: string };

const buildError = (error: Error | any) => {
  if (error.response) {
    const { status, statusText, url } = error.response;
    return `Request failed with status ${status} (${statusText}) at ${url}`;
  }
  return error;
};

export const reducer = (
  state: OrderLoaderState,
  action: Action
): OrderLoaderState => {
  switch (action.type) {
    case "START_LOAD": {
      switch (state.status) {
        case "unloadedSaved":
          return {
            status: "loadingSaved",
            loading: true,
            order: null,
            savedOrderId: state.savedOrderId,
            error: null,
          };
        case "unloadedNoSaved":
          return {
            status: "loadingNoSaved",
            loading: true,
            order: null,
            savedOrderId: null,
            error: null,
          };

        case "loaded": {
          return {
            ...state,
            status: "refreshing",
            loading: true,
          };
        }
        default:
          return state;
      }
    }
    case "LOAD_SUCCESS": {
      return {
        status: "loaded",
        loading: false,
        order: action.order,
        savedOrderId: action.order.id!,
        error: null,
      };
    }

    case "LOAD_NOT_FOUND": {
      // When we get a 404, put state back
      // into unloaded and removed saved id.
      // This kicks off a new request
      if (state.status === "loadingSaved") {
        return {
          status: "unloadedNoSaved",
          loading: false,
          order: null,
          savedOrderId: null,
          error: null,
        };
      }
      // In any other case of getting a 404,
      // we put the app into "orderless" status.
      // We don't expect this to be a likely case,
      // but if it does happen it may indicate a data problem
      // and we still want to allow the user to navigate the
      // app and request assistance.
      return {
        status: "orderless",
        loading: false,
        order: null,
        savedOrderId: null,
        error: null,
      };
    }

    case "LOAD_ERROR": {
      return {
        status: "error",
        loading: false,
        order: null,
        savedOrderId: null,
        error: buildError(action.error),
      };
    }

    case "RESET": {
      if (action.orderId) {
        return {
          status: "unloadedSaved",
          loading: false,
          order: null,
          savedOrderId: action.orderId,
          error: null,
        };
      }

      return {
        status: "unloadedNoSaved",
        loading: false,
        order: null,
        savedOrderId: null,
        error: null,
      };
    }
  }
};

//------------------------
// OrderLoaderProvider component
//------------------------

const SAVED_ORDER_ID_KEY = "selected-order-id";

const getInitialState = (
  requestedOrderId: string | null | undefined
): OrderLoaderState => {
  if (requestedOrderId) {
    return {
      status: "unloadedSaved",
      loading: false,
      order: null,
      savedOrderId: requestedOrderId,
      error: null,
    };
  }

  if (typeof window !== "undefined") {
    const savedId = window.localStorage.getItem(SAVED_ORDER_ID_KEY);
    if (savedId !== null) {
      return {
        status: "unloadedSaved",
        loading: false,
        order: null,
        savedOrderId: savedId,
        error: null,
      };
    }
  }
  return {
    status: "unloadedNoSaved",
    loading: false,
    order: null,
    savedOrderId: null,
    error: null,
  };
};

export const OrderLoaderProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const searchParams = useSearchParams();
  const requestedOrderId = searchParams?.get("order_id");
  const requestingSpecificOrder = useRef(requestedOrderId !== undefined);
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(requestedOrderId)
  );
  const { isAuthenticated } = useAuth();
  const { notify } = useNotify();
  const [getCurrentShipment] = useCurrentShipmentLazyQuery();

  const get = useCallback(
    async (orderId: string | null) => {
      if (state.loading) {
        return;
      }

      const load = async () => {
        // If there's an ID saved in storage, load id. If not, we load the current shipment/order
        // based on whether the MTO has multiple shipments, the order in which they were created,
        // and/or whether counseling has been completed.
        if (orderId === null) {
          const result = await getCurrentShipment();
          const currentShipmentId = result?.data?.currentShipment?.id;

          return currentShipmentId
            ? Order.includes(includedFields)
                .selectExtra(extraAttributes)
                .find(Number(currentShipmentId))
            : null;
        } else {
          return Order.includes(includedFields)
            .selectExtra(extraAttributes)
            .find(Number(orderId));
        }
      };

      try {
        dispatch({ type: "START_LOAD", orderId });
        const orderResult = await load();
        if (orderResult && orderResult.data) {
          dispatch({ type: "LOAD_SUCCESS", order: orderResult.data });
        } else {
          dispatch({ type: "LOAD_NOT_FOUND" });
        }
        return orderResult?.data ?? null;
      } catch (e: any) {
        let status = e?.response?.status;
        if (status === 404 && !requestingSpecificOrder.current) {
          dispatch({ type: "LOAD_NOT_FOUND" });
        } else {
          dispatch({ type: "LOAD_ERROR", error: e as Error });
          notify(`There was an error loading order id ${orderId}`, {
            variant: "error",
          });
        }
        return null;
      }
    },
    [dispatch, state]
  );

  const refresh = useCallback(() => {
    return get(state.savedOrderId);
  }, [get, state]);

  const changeOrder = useCallback(
    (orderId: string) => {
      if (orderId !== state.order?.id) {
        dispatch({ type: "RESET", orderId });
      }
    },
    [dispatch, state]
  );

  const reset = useCallback(
    (orderId?: string) => {
      dispatch({ type: "RESET", orderId });
    },
    [dispatch]
  );

  useEffect(() => {
    // Kicks off a new get flow whenever we put
    // the state machine into unloadedNoSaved or unloadedSaved
    if (
      isAuthenticated &&
      (state.status === "unloadedNoSaved" || state.status === "unloadedSaved")
    ) {
      get(state.savedOrderId);
    }
  }, [state, get, isAuthenticated]);

  useEffect(() => {
    // Updates local storage whenever value changes
    if (state.savedOrderId) {
      window.localStorage.setItem(SAVED_ORDER_ID_KEY, state.savedOrderId);
    } else {
      window.localStorage.removeItem(SAVED_ORDER_ID_KEY);
    }
  }, [state.savedOrderId]);

  useEffect(() => {
    // Posts the loaded order id to datadog
    if (state.order) {
      datadogRum.setGlobalContextProperty("orderId", state.order.id);
    }
  }, [state.order]);

  const contextVal: OrderLoaderContext = useMemo(() => {
    return {
      ...state,
      refresh,
      reset,
      changeOrder,
    };
  }, [state, refresh, reset, changeOrder]);

  return (
    <OrderLoaderContext.Provider value={contextVal}>
      {children}
    </OrderLoaderContext.Provider>
  );
};

export const useOrderLoader = () => useContext(OrderLoaderContext);

/*
 * Hook for getting the order IF it is available. This hook provides
 * no guarantees that the order will be available but doesn't rely upon
 * <OrderGuard>.
 * @returns Order | null
 */
export const useMaybeOrder = () => {
  const { order } = useOrderLoader();
  return order;
};

/*
 * Convenience hook to refresh the current order
 */
export const useRefreshOrder = () => {
  const { refresh, loading } = useOrderLoader();
  return {
    refresh,
    loading,
  };
};

import { Attr, BelongsTo, Model } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { InventoryItem } from "./InventoryItem";
import { Service } from "./Service";

@Model()
export class ItemActivity extends ApplicationRecord {
  static jsonapiType = "item_activities";

  @Attr() serviceType!: string;
  @Attr() status!: string;
  @BelongsTo() inventoryItem!: InventoryItem;
}

import { ClaimDisplayStatus, ClaimStatus } from "types/Claims";
import { Claim, ClaimDetailOffer } from "models";
import { OfferStatus } from "constant/OfferStatus";

/**
 * This interface will match either a claim or claim detail
 */
interface StatusableClaim {
  currentOffer: ClaimDetailOffer | null;
  status: ClaimStatus;
  id?: string;
  claim?: Claim;
}

/**
 * Takes a claim or claim detail and calculates the display
 * status based on the status on the object & its associated offers.
 * This logic should live on the backend and we will move it
 * when we have time.
 * @param claim
 * @returns A display status we can use to render status pills
 *
 * TODO: Right now it does not seem like this function is doing much.
 * This is because we dumbed down a previously more complex algorithm
 * that is going to be completely manual for testing/demo.
 */
export const getClaimDisplayStatus = (
  claim: StatusableClaim
): ClaimDisplayStatus => {
  const actionRequired =
    claim.status !== "transferred_to_mco" &&
    claim.currentOffer?.status === OfferStatus.sent;

  if (actionRequired) {
    return "ACTION_REQUIRED";
  }

  switch (claim.status) {
    case ClaimStatus.countered:
      return "COUNTERED";
    case ClaimStatus.offer_accepted:
      // Show denial status for ClaimDetail associated with a denied claim
      const hasAcceptedDenial = claim.claim?.status === "denied";
      return hasAcceptedDenial ? "DENIED" : "ACCEPTED";
    case ClaimStatus.offered:
      return "OFFERED";
    case ClaimStatus.inspection:
      return "INSPECTION";
    case ClaimStatus.assigned:
      return "ASSIGNED";
    case ClaimStatus.in_process:
      return "IN_PROCESS";
    case ClaimStatus.paid:
      return "PAID";
    case ClaimStatus.submitted:
      return "SUBMITTED";
    case ClaimStatus.unsubmitted:
      return "UNSUBMITTED";
    case ClaimStatus.canceled:
      return "CANCELED";
    case ClaimStatus.closed:
      return "CLOSED";
    case ClaimStatus.denied:
      return "DENIED";
    case ClaimStatus.transferred_to_mco:
      return "TRANSFERRED_TO_MCO";
    case ClaimStatus.escalated_to_ppso_jppso:
      return "ESCALATED_TO_PPSO_JPPSO";
    default: {
      const _exhaustiveCheck: never = claim.status;
      return _exhaustiveCheck;
    }
  }
};

import { Model, Attr } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";

@Model()
export class Document extends ApplicationRecord {
  static jsonapiType = "documents";

  @Attr() number!: string;
  @Attr() title!: string;
  @Attr() filename!: string;
}

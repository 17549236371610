import { Model, Attr, HasOne, HasMany } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { Temporal } from "@movehq/datetime";
import { User } from "./User";
import { Order } from "models";
import { InteractionMessage } from "./InteractionMessage";
import { formatTime } from "@movehq/datetime";

type CommunicationChannel = "phone" | "email" | "sms" | "chat";

@Model()
export class Interaction extends ApplicationRecord {
  static jsonapiType = "interactions";
  static apiNamespace = "/api/v1/comms";
  @Attr() orderId!: string;
  @Attr() agentId!: string;
  @Attr() communicationChannel!: CommunicationChannel;
  @Attr() startTime!: string;
  @Attr() endTime?: string;
  @Attr() duration!: string;
  @Attr() message!: string;
  @Attr() status!: string;
  @Attr() subject!: string;
  @Attr() from!: string | null;
  @Attr() to!: Array<string> | null;
  @Attr() cc!: Array<string> | null;
  @Attr() bcc!: Array<string> | null;
  @HasOne() agent!: User | null;
  @HasOne() order!: Order;
  @HasMany() messages!: InteractionMessage[];

  get isSingleMessage() {
    return this.messages.length === 1;
  }

  get agentTitle() {
    if (this.agent) {
      return this.agent.fullName;
    }

    if (this.agentId) {
      return `Agent ID: ${this.agentId}`;
    }

    return "System";
  }

  get startTimeFormat() {
    return formatTime(this.startTime, "numericShortMonthWithTime");
  }

  get endTimeFormat() {
    if (this.endTime) {
      return formatTime(this.endTime, "numericShortMonthWithTime");
    }
    return "-";
  }

  get durationFormat() {
    if (this.duration) {
      const d = Temporal.Duration.from(this.duration);
      // Ideally we would return d.toLocaleString() but
      // currently browser support is mostly absent, it
      // would only work on Safari
      return `${d.minutes}m ${d.seconds}s`;
    }

    return "-";
  }

  get communicationChannelNormalized() {
    const match = this.communicationChannel
      .toLowerCase()
      .match(/.*(email|phone|chat|sms).*/);
    if (!match) {
      return this.communicationChannel;
    }

    return match[1] as CommunicationChannel;
  }

  get channelName() {
    switch (this.communicationChannelNormalized) {
      case "chat":
        return "Chat";
      case "email":
        return "Email";
      case "sms":
        return "SMS";
      case "phone":
        return "Phone";
      default:
        return this.communicationChannel;
    }
  }
}

import { OptionsObject } from "notistack";
import { ResponseError } from "spraypaint/lib/request";
import { claimNotifyErrorMessages } from "../types";

const isResponseError = (e: any): e is ResponseError => !!e?.response?.status;

function getErrorMessage(err: any) {
  let statusCode = 0;
  if (isResponseError(err)) {
    statusCode = err.response?.status || 0;
  }

  let errMsg = claimNotifyErrorMessages.default;
  statusCode = parseInt(String(statusCode));

  if (statusCode === 401) {
    errMsg = claimNotifyErrorMessages.unauthorized;
  } else if (statusCode === 422) {
    errMsg = claimNotifyErrorMessages.invalidData;
  } else if (statusCode >= 500 && statusCode <= 599) {
    errMsg = claimNotifyErrorMessages.serviceError;
  }
  return errMsg;
}

export async function handleModelSaveAndException(
  saveModelFunction: () => void,
  notify: (message?: string, options?: OptionsObject) => void
) {
  try {
    await saveModelFunction();
  } catch (err) {
    const errMsg = getErrorMessage(err);

    notify(errMsg, { variant: "error" });
  }
}

import { Model, BelongsTo, Attr } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { Service } from "./Service";

@Model()
export class Transaction extends ApplicationRecord {
  static jsonapiType = "transactions";

  @Attr() service_id!: string;
  @Attr() type!: string;
  @Attr() sub_type!: string;
  @Attr() amount!: string;
  @Attr() status!: string;

  @BelongsTo() service!: Service;
}

import { Model, Attr, HasMany, BelongsTo } from "spraypaint";
import { DepotServiceCompletionStatus, DepotServiceTypes } from "types";
import { ApplicationRecord } from "./ApplicationRecord";
import { Order } from "./Order";
import { ServiceDocument } from "./ServiceDocument";
import { Transaction } from "./Transaction";
import { ServiceSubtype } from "__generated__/types";

@Model()
export class Service extends ApplicationRecord {
  static jsonapiType = "services";
  @Attr() orderId!: string;
  @Attr() type!: DepotServiceTypes;
  @Attr() completionStatus!: DepotServiceCompletionStatus;
  @Attr() status!: string | null;
  @Attr() statusUpdatedAt!: string | null;
  @Attr() serviceProviderId!: string | null;
  @Attr() actualGrossWeight!: string | null;
  @Attr() actualTareWeight!: string | null;
  @Attr() subtype!: ServiceSubtype | null;
  @Attr() plannedDatetimeRange!: {
    begin: string;
    end: string;
  } | null;
  @Attr() scheduledDaterange!: {
    begin: string;
    end: string;
  } | null;
  @Attr() plannedDaterange!: {
    begin: string;
    end: string;
  } | null;
  @Attr() createdAt!: string;
  @Attr() updatedAt!: string;
  @Attr() displayName!: string;
  @Attr() partialStorage?: boolean;
  @HasMany() transactions!: Transaction[];
  @HasMany() documents!: ServiceDocument[];
  @BelongsTo() order!: Order;
}

import { Model, BelongsTo, Attr } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { Order } from "./Order";

@Model()
export class User extends ApplicationRecord {
  static jsonapiType = "users";

  @Attr() firstName!: string;
  @Attr() lastName!: string;
  @BelongsTo() order!: Order;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

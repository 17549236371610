const FeatureFlags = {
  FF_ENTITLEMENTS_GUI_110923:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_ENTITLEMENTS_GUI_110923 === "enabled",
  FF_DELETE_CLAIM_ITEMS:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DELETE_CLAIM_ITEMS === "enabled",
  FF_END_CALL: process.env.NEXT_PUBLIC_FEATURE_FLAG_END_CALL === "enabled",
  FF_SUBMIT_CLAIM_CONFIRMATION:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_SUBMIT_CLAIM_CONFIRMATION ===
    "enabled",
};

export function isFeatureEnabled(featureFlagName: keyof typeof FeatureFlags) {
  return FeatureFlags[featureFlagName];
}

import { Attr, Model } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { CounselingStatusId } from "../types";
import { MoveTypeId } from "../types/MoveTypes";

@Model()
export class MoveType extends ApplicationRecord {
  static jsonapiType = "move_types";

  @Attr() orderId!: number;
  @Attr() counselingStatus!: CounselingStatusId;
  @Attr() moveType!: MoveTypeId;
  @Attr() sitRequested!: boolean;
  @Attr() incentiveEstimate!: number;
  @Attr() preferredPackDate!: string; // date
  @Attr() preferredLoadDate!: string; // date
  @Attr() preferredDeliveryDate!: string; // date
  @Attr() surveyType!: string; // enum
  @Attr() surveyRequestDate!: string; // date
  @Attr() surveyRequestTime!: string;
}

import { displayDollarsAndCents } from "components/common/layouts/InventoryPageLayout/components/ClaimsDisplay/ClaimsList/moneyConversions";
import { CounterOffer } from "components/pages/ClaimDetailsPage/CounterModal";
import { Attr, HasMany, Model } from "spraypaint";
import { ClaimDisplayStatus, ClaimStatus } from "types/Claims";
import { OfferScope } from "../constant/OfferScope";
import { OfferStatus } from "../constant/OfferStatus";
import { OfferType } from "../constant/OfferType";
import { ApplicationRecord } from "./ApplicationRecord";
import { Claim } from "./Claim";
import { ClaimDetail } from "./ClaimDetail";
import { ClaimDetailOfferActivity } from "./ClaimDetailOfferActivity";

@Model()
export class ClaimDetailOffer extends ApplicationRecord {
  static jsonapiType = "claim_detail_offers";
  @Attr() claimDetailOfferType!: OfferType;
  @Attr() offerScope!: OfferScope;
  @Attr() createdAt!: string;
  @Attr() updatedAt!: string;
  @HasMany() claimDetails!: ClaimDetail[];
  @HasMany() claimDetailOfferActivities?: ClaimDetailOfferActivity[];

  get status(): OfferStatus | undefined {
    return this.mostRecentActivity?.status;
  }

  get displayStatus(): ClaimDisplayStatus {
    if (this.mostRecentActivity) {
      return this.mostRecentActivity.displayStatus;
    }

    throw Error("Claim detail offer is missing an offer activity");
  }

  get mostRecentActivity(): ClaimDetailOfferActivity | undefined {
    return this?.claimDetailOfferActivities
      ?.filter((activity) => activity.status !== OfferStatus.unsent)
      .sort((a, b) =>
        new Date(b.createdAt!) > new Date(a.createdAt!) ? 1 : -1
      )
      .at(0);
  }

  get offering() {
    if (this.claimDetailOfferType === OfferType.cash) {
      return displayDollarsAndCents(this.mostRecentActivity?.valueAmountUsd);
    }
    if (this.claimDetailOfferType === OfferType.repair) {
      return `Item Repair`;
    }
  }

  get isDenial() {
    return this.claimDetailOfferType === OfferType.denied;
  }
}

export const handleCounterOffer = async (
  offer: ClaimDetailOffer,
  scopedTo: Claim | ClaimDetail,
  submittedCounter: CounterOffer
) => {
  const activity = offer.mostRecentActivity;

  if (activity) {
    activity.status = OfferStatus.countered;
    activity.counteredDescription = submittedCounter.counteredDescription;
    activity.counteredValueAmountUsd =
      submittedCounter.counteredValueAmountDollars;
    if (!(await activity.save())) throw new Error("error saving activity");

    if (submittedCounter.sendToMto) {
      scopedTo.status = ClaimStatus.transferred_to_mco;
    }

    if (!(await scopedTo.save()))
      throw new Error("error saving claim or claim detail");
  }
};

import { Model, Attr } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";

import { stringToZonedDateTimeFallbackLocal } from "@movehq/datetime";

type Coordinates = {
  latitude: number;
  longitude: number;
};

@Model()
export class CrewTracker extends ApplicationRecord {
  static jsonapiType = "crew_trackers";

  @Attr() currentLocation!: Coordinates;
  @Attr() destination!: Coordinates;
  @Attr() estimatedArrival!: string;
  @Attr() distanceInMiles!: number;

  get estimatedArrivalTime() {
    return stringToZonedDateTimeFallbackLocal(this.estimatedArrival);
  }
}

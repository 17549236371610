import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";
import { ConditionsList } from "types";
import { ApplicationRecord } from "./ApplicationRecord";
import { CargoClaimDetail, ClaimDetail } from "./ClaimDetail";
import { InventoryRoom } from "./InventoryRoom";
import { ItemActivity } from "./ItemActivity";
import { LossOrDamageNotice } from "./LossOrDamageNotice";

type TagColorOption =
  | "RED"
  | "YELLOW"
  | "GREEN"
  | "ORANGE"
  | "BLUE"
  | "MULTI"
  | "WHITE";

type InventoryItemStatus =
  | "PACKED"
  | "IN_TRANSIT"
  | "IN_STORAGE"
  | "DELIVERED"
  | "VOIDED"
  | "MISSING";

@Model()
export class InventoryItem extends ApplicationRecord {
  static jsonapiType = "inventory_items";

  @Attr() itemName!: string;
  @Attr() lotNumber!: string;
  @Attr() tagNumber!: string;
  @Attr() tagColor!: TagColorOption;
  @Attr() images!: Array<string>;
  @Attr() conditionsAtOrigin!: ConditionsList;
  @Attr() status!: InventoryItemStatus;
  @BelongsTo() inventoryRoom!: InventoryRoom;
  @HasMany() claimDetails!: CargoClaimDetail[];
  @HasOne() lossOrDamageNotice!: LossOrDamageNotice | null;
  @HasMany() itemActivities!: ItemActivity[];

  /**
   * Primary thumbnail image url for the item
   * @returns string
   */
  get imageUrl(): string {
    return this?.images?.[0] || "/images/inventory/default.svg";
  }

  get itemNumberDisplay(): string {
    return `${this.lotNumber}-${this.tagNumber}`;
  }

  /**
   * Comma-delimited string consisting of the item conditions at its origin
   * @returns string
   */
  get originConditions(): string {
    return this?.conditionsAtOrigin.reduce(
      (conditionsString, { conditions }) => {
        const conditionList = conditions
          .map((condition) => `${condition.locations} ${condition.damages}`)
          .join(", ");
        return conditionsString.length
          ? `${conditionsString}, ${conditionList}`
          : conditionList;
      },
      ""
    );
  }

  /**
   * Gets whether the item has been added to an unsubmitted cargo claim
   */
  get hasUnsubmittedClaim(): boolean {
    return this?.claimDetails?.some(
      (claimDetail) => claimDetail?.status === "unsubmitted"
    );
  }

  get claimable(): boolean {
    return (
      (this.status === "DELIVERED" ||
        !!this.itemActivities.find(
          (activity) =>
            activity.serviceType === "DELIVERY" && activity.status === "MISSING"
        )) &&
      !this.hasUnsubmittedClaim
    );
  }
}

export const getImage = (item?: InventoryItem): string => {
  return item?.images?.[0] || "/images/inventory/default.svg";
};

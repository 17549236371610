import { orderStatuses } from "constant/orderStatuses";
import { OrderStatus, DepotStatus } from "types";

const unknownOrderStatus: OrderStatus = {
  id: "UNKNOWN",
  value: -1,
  label: "moveStatus.unknown",
  percentComplete: -1,
  display: () => false,
};

/**
 * Get an order status value based on its DepotStatus
 * @param statusId
 * @returns number
 */
export const getOrderStatusValue = (statusId: DepotStatus): number => {
  const status =
    orderStatuses.find((item) => item.id === statusId) || unknownOrderStatus;
  return status.value;
};

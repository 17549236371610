import { Model, Attr, HasOne, HasMany } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { Interaction } from "./Interaction";

@Model()
export class InteractionMessage extends ApplicationRecord {
  static jsonapiType = "messages";
  static apiNamespace = "/api/v1/comms";
  @Attr() partytype!: "CLIENT" | "AGENT" | "SYSTEM" | null;
  @Attr() party!: string;
  @Attr() text!: string;
  @HasOne() interaction!: Interaction;

  get partyFormatted() {
    if (this.partytype) {
      switch (this.partytype) {
        case "CLIENT":
          return "You";
        case "AGENT":
          return "Agent";
        case "SYSTEM":
          return "System";
      }
    }

    return this.party || "System";
  }

  get textAsHtml() {
    return this.text.replace(/(?:\r\n|\r|\n)/g, "<br/>");
  }
}

import { ClaimStatus } from "types";

// See https://docs.google.com/spreadsheets/d/1GiMAb1kDbdjLMTN5uP7EWWZ3NzJGqQPLUEeB732FwU4/edit#gid=2050729031
// for customer-frontend status text
export const claimBadgeText: { [key in ClaimStatus]: string } = {
  submitted: "Claim Submitted",
  unsubmitted: "Claim Unsubmitted",
  canceled: "Claim Canceled",
  closed: "Claim Closed",
  assigned: "Assigned",
  in_process: "In Process",
  inspection: "Inspection Ordered",
  offered: "Offer Pending",
  countered: "In Process",
  offer_accepted: "Accepted",
  paid: "Paid",
  denied: "Denied",
  transferred_to_mco: "Flagged to MCO",
  escalated_to_ppso_jppso: "Escalated to PPSO / JPPSO",
};

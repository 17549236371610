import { envEnabled } from "./getEnv";

const debugStyle = [
  "background-color: blue",
  "border-radius: 3px",
  "padding: 5px",
  "color: white",
  "font-weight: bold",
].join(";");

/**
 * Debug function which only displays output when not in production.
 *
 * @param context?: string If provided along with multiple other arguments will be assumed
 *   to be the context of the log and will be styled accordingly
 * @param data data to print in the console.
 */
export function debug(...data: any[]) {
  let context = "DEBUG";
  if (data.length > 1 && typeof data[0] === "string") {
    context = data[0];
    data = data.slice(1);
  }
  if (envEnabled("NEXT_PUBLIC_DEBUGGING")) {
    // eslint-disable-next-line no-console
    console.log.apply(null, [`%c${context}`, debugStyle, ...data]);
  }
}

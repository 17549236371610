import { AddressFormData } from "types";

export const validateStreet = (value: any) => {
  if (!value) {
    return "We need the street address";
  }
};

export const validateState = (value: any) => {
  if (!value) {
    return "We need the state";
  }
};

export const validateCity = (value: any) => {
  if (!value) {
    return "We need the city";
  }
};

export const validateZip = (value: any) => {
  if (!value || typeof value !== "string") {
    return "We need the zip code";
  }

  if (!value.match(/^[0-9]+$/)) {
    return "Must be only digits";
  }

  if (value.length !== 5) {
    return "Must be exactly 5 digits";
  }
};

export const validateAddress = (value: Partial<AddressFormData>) => {
  const errors = {
    street1: validateStreet(value.street1),
    locality: validateCity(value.locality),
    postalCode: validateZip(value.postalCode),
    region: validateState(value.region),
  };

  if (
    Object.values(errors).filter((value) => value !== undefined).length === 0
  ) {
    return undefined;
  }

  return errors;
};

export const validateBooleanOption = (value: any) => {
  if (value !== "0" || value !== "1") {
    return "Please select an option";
  }
  return undefined;
};

export const validateMaybeAddress = (
  value: Partial<AddressFormData & { hasAddress: "0" | "1" }>
) => {
  if (!value.hasAddress) {
    return {
      hasAddress: "Please select an option",
    };
  }
  if (value.hasAddress === "1") {
    return validateAddress(value);
  }
};

export enum ClaimType {
  cargo = "cargo",
  inconvenience = "inconvenience",
  property = "property",
}

/**
 * Depot claim statuses
 */
export const ClaimStatus = {
  unsubmitted: "unsubmitted",
  submitted: "submitted",
  closed: "closed",
  canceled: "canceled",
  assigned: "assigned",
  in_process: "in_process",
  inspection: "inspection",
  offered: "offered",
  countered: "countered",
  offer_accepted: "offer_accepted",
  paid: "paid",
  denied: "denied",
  transferred_to_mco: "transferred_to_mco",
  escalated_to_ppso_jppso: "escalated_to_ppso_jppso",
} as const;

export type ClaimStatusMap = typeof ClaimStatus;

export type ClaimStatus = ClaimStatusMap[keyof ClaimStatusMap];

export const ClaimStatusAsList = Object.keys(ClaimStatus) as ClaimStatus[];

/**
 * These are the statuses we show on claims and claim details in the UI
 */
export const claimDisplayStatuses = [
  "UNSUBMITTED",
  "SUBMITTED",
  "OFFERED",
  "ACCEPTED",
  "IN_PROCESS",
  "INSPECTION",
  "ASSIGNED",
  "COUNTERED",
  "CLOSED",
  "CANCELED",
  "PAID",
  "INVALID",
  "DENIED",
  "ACTION_REQUIRED",
  "TRANSFERRED_TO_MCO",
  "ESCALATED_TO_PPSO_JPPSO",
] as const;

export type ClaimDisplayStatus = (typeof claimDisplayStatuses)[number];

export const claimNotifyErrorMessages = {
  default: "Something went wrong, please contact HomeSafe Customer Care.",
  serviceError: "An issue occurred, please try again later.", // 5xx Errors
  unauthorized: "Session expired, please log in.",
  invalidData:
    "Invalid data, please modify your input and try again.  If you continue to see this error, please contact HomeSafe Customer Care.",
};
